import React, { ChangeEvent, useRef } from "react";
import { Form } from "react-bootstrap";
import { useUploadFileMutation } from "../store/query";
import { errorDisplayOrNavigate } from "../utilities/notificationToast";

interface Props<T extends DocType> {
  name: string;
  errorForm: string;
  loading: boolean;
  isInvalid: boolean;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  setFieldError: (field: string, message: string | undefined) => void;
  docData: T;
}

type DocType = { name: string; file: string }[];

const UploadFile = <T extends DocType>({
  name,
  errorForm,
  loading,
  setFieldError,
  setFieldValue,
  isInvalid,
  docData,
}: Props<T>) => {
  const ref = useRef<HTMLInputElement>();
  const [uploadMutate, { isLoading, reset, error, isSuccess }] =
    useUploadFileMutation();

  const isLoad = isLoading || loading;

  const handleUpload = async (value: File) => {
    const data = await uploadMutate({
      endpoint: "/homeowner/arc/upload/document",
      file: value,
      formKey: "file",
    }).unwrap();

    if (data) {
      setFieldValue(
        name,
        docData && docData.length > 0
          ? [...docData, { name: value.name, file: data.directoryPath }]
          : [{ name: value.name, file: data.directoryPath }],
        false
      );
      setFieldError(name, "");
    }

    ref.current.value = "";
  };

  if (isSuccess) {
    reset();
  }

  if (error) {
    reset();

    errorDisplayOrNavigate({ error: error, toastId: "upload-error" });
  }

  return (
    <>
      <Form.Control
        ref={ref}
        type="file"
        name={name}
        isInvalid={isInvalid}
        disabled={isLoad}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          handleUpload(e.target.files[0])
        }
      />
      {errorForm && (
        <Form.Control.Feedback type="invalid">
          {errorForm}
        </Form.Control.Feedback>
      )}
    </>
  );
};

export default UploadFile;
