export const homeownerTags: string[] = ["homeowner-list"];
export const violationTags: string[] = [
  "violation-list",
  "violation-timeline",
  "violation-timeline-details",
  "violation-comments",
];
export const votingTags: string[] = ["voting-list"];
export const blogtags: string[] = ["blog-list"];
export const arctags: string[] = ["arc=list"];
export const newstags = ["news-list"];
export const dashboardtags = ["request-count"];
export const documenttags = ["document-list"];
export const eventTags = ["event-list"];
