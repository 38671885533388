// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#user-chat-message img {
  width: 100%;
}

.ql-blank {
  padding-bottom: 0 !important;
}

.ql-toolbar.ql-snow {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  padding: 0 !important;
}

.ql-formats {
  position: absolute !important;
  bottom: 5px !important;
  right: 55px !important;
  margin-right: 0 !important;
  z-index: 9999 !important;
}

.ql-editor {
  padding: 7px 10px !important;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/customChat.scss"],"names":[],"mappings":"AACE;EACE,WAAA;AAAJ;;AAIA;EACE,4BAAA;AADF;;AAIA;EACE,2BAAA;EACA,4BAAA;EACA,6BAAA;EACA,qBAAA;AADF;;AAIA;EACE,6BAAA;EACA,sBAAA;EACA,sBAAA;EACA,0BAAA;EACA,wBAAA;AADF;;AAIA;EACE,4BAAA;AADF","sourcesContent":["#user-chat-message {\n  img {\n    width: 100%;\n  }\n}\n\n.ql-blank {\n  padding-bottom: 0 !important;\n}\n\n.ql-toolbar.ql-snow {\n  border-top: none !important;\n  border-left: none !important;\n  border-right: none !important;\n  padding: 0 !important;\n}\n\n.ql-formats {\n  position: absolute !important;\n  bottom: 5px !important;\n  right: 55px !important;\n  margin-right: 0 !important;\n  z-index: 9999 !important;\n}\n\n.ql-editor {\n  padding: 7px 10px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
