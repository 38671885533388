import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Container,
  Nav,
  Tab,
  OverlayTrigger,
  Tooltip,
  Button,
  Accordion,
  Form,
  InputGroup,
} from "react-bootstrap";
import Card from "../../../components/Card";
import { Link, useNavigate } from "react-router-dom";
import ReactFsLightbox from "fslightbox-react";

import user1 from "../../../assets/images/user/1.jpg";
import user01 from "../../../assets/images/user/01.jpg";

import pageBgImg from "../../../assets/images/page-img/profile-bg1.jpg";

//full calender
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import * as moment from "moment";
import "../../../assets/scss/fullcalendarCustom.scss";

import { FaPlus, FaTrash } from "react-icons/fa";

//flatpickr
import Flatpickr from "react-flatpickr";

// Fslightbox plugin
const FsLightbox = ReactFsLightbox.default
  ? ReactFsLightbox.default
  : ReactFsLightbox;

const UserProfile = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [inputs, setInputs] = useState([{ fullName: "", phone: "" }]);
  const [cars, setCars] = useState([
    { make: "", model: "", year: "", color: "", plate: "" },
  ]);
  const navigate = useNavigate();

  useEffect(() => {
    document.body.classList.add("profile-page");
    return () => {
      document.body.classList.remove("profile-page");
    };
  });

  const events = [
    {
      title: "5:30a Click for Google",
      url: "http://google.com/",
      start: moment(new Date(), "YYYY-MM-DD")
        .add(-20, "days")
        .format("YYYY-MM-DD"),
      color: "#50b5ff",
    },
    {
      title: "5:30a All Day Event",
      start: moment(new Date(), "YYYY-MM-DD")
        .add(-18, "days")
        .format("YYYY-MM-DD"),
      color: "#a09e9e",
    },
    {
      title: "5:30a Long Event",
      start: moment(new Date(), "YYYY-MM-DD")
        .add(-16, "days")
        .format("YYYY-MM-DD"),
      end: moment(new Date(), "YYYY-MM-DD")
        .add(-13, "days")
        .format("YYYY-MM-DD"),
      color: "#49f0d3",
    },
    {
      groupId: "999",
      title: "5:30a Repeating Event",
      start: moment(new Date(), "YYYY-MM-DD")
        .add(-14, "days")
        .format("YYYY-MM-DD"),
      color: "#ffba68",
    },
    {
      groupId: "999",
      title: "5:30a Repeating Event",
      start: moment(new Date(), "YYYY-MM-DD")
        .add(-12, "days")
        .format("YYYY-MM-DD"),
      color: "#d592ff ",
    },
    {
      groupId: "999",
      title: "5:30a Repeating Event",
      start: moment(new Date(), "YYYY-MM-DD")
        .add(-10, "days")
        .format("YYYY-MM-DD"),
      color: "#ff9b8a",
    },
    {
      title: "5:30a Birthday Party",
      start: moment(new Date(), "YYYY-MM-DD")
        .add(-8, "days")
        .format("YYYY-MM-DD"),
      color: "#49f0d3",
    },
    {
      title: "5:30a Meeting",
      start: moment(new Date(), "YYYY-MM-DD")
        .add(-6, "days")
        .format("YYYY-MM-DD"),
      color: "#a09e9e",
    },
    {
      title: "5:30a Birthday Party",
      start: moment(new Date(), "YYYY-MM-DD")
        .add(-5, "days")
        .format("YYYY-MM-DD"),
      color: "#49f0d3",
    },
    {
      title: "5:30a Birthday Party",
      start: moment(new Date(), "YYYY-MM-DD")
        .add(-2, "days")
        .format("YYYY-MM-DD"),
      color: "#ff9b8a ",
    },

    {
      title: "5:30a Meeting",
      start: moment(new Date(), "YYYY-MM-DD")
        .add(0, "days")
        .format("YYYY-MM-DD"),
      color: "#ff9b8a",
    },
    {
      title: "5:30a Click for Google",
      url: "http://google.com/",
      start: moment(new Date(), "YYYY-MM-DD")
        .add(0, "days")
        .format("YYYY-MM-DD"),
      color: "#d592ff",
    },
    {
      groupId: "999",
      title: "5:30a Repeating Event",
      start: moment(new Date(), "YYYY-MM-DD")
        .add(0, "days")
        .format("YYYY-MM-DD"),
      color: "#49f0d3",
    },
    {
      title: "5:30a Birthday Party",
      start: moment(new Date(), "YYYY-MM-DD")
        .add(0, "days")
        .format("YYYY-MM-DD"),
      color: "#f4a965",
    },
    {
      title: "5:30a Doctor Meeting",
      start: moment(new Date(), "YYYY-MM-DD")
        .add(0, "days")
        .format("YYYY-MM-DD"),
      color: "#f4a965",
    },
    {
      title: "5:30a All Day Event",
      start: moment(new Date(), "YYYY-MM-DD")
        .add(1, "days")
        .format("YYYY-MM-DD"),
      color: " #50b5ff",
    },
    {
      groupId: "999",
      title: "5:30a Repeating Event",
      start: moment(new Date(), "YYYY-MM-DD")
        .add(8, "days")
        .format("YYYY-MM-DD"),
      color: " #50b5ff",
    },
    {
      groupId: "999",
      title: "5:30a Repeating Event",
      start: moment(new Date(), "YYYY-MM-DD")
        .add(10, "days")
        .format("YYYY-MM-DD"),
      color: "#49f0d3",
    },
  ];

  const data = [
    {
      control: "INV00001",
      Description: "Monthly Fee",
      DateIssued: "	05-1-24",
      DateDue: "	05-15-24",
      Amount: "$300	",
      Status: "Unpaid",
    },
  ];

  const request = [
    {
      Reference: "000001",
      CreatedBy: "Thomas Hadden",
      Type: "ARC",
      Description: "Remodel of parking",
      DateCreated: "03-04-24",
      DateClosed: "04-04-24",
      Status: "Closed",
    },
    {
      Reference: "000002",
      CreatedBy: "5000 Moretto Court",
      Type: "ARC",
      Description: "Roof Fixes",
      DateCreated: "05-01-24",
      DateClosed: "--------",
      Status: "Ongoing",
    },
  ];

  const [Add, setAdd] = useState(request);
  const [addFormData] = useState({
    Reference: "",
    CreatedBy: "",
    Type: "",
    Description: "",
    DateCreated: "",
    DateClosed: "",
    Status: "",
  });

  const addNewValue = () => {
    setAdd(Add.concat(request[1]));
  };

  const handleAddFormChanges = (event) => {
    event.preventDefault();
    const Reference = event.target.closest("tr").getAttribute("dataReference");
    const tdElem = event.target.closest("tr").querySelectorAll("td");
    const obj = {
      Reference: Reference,
    };
    Array.from(tdElem, (elem) => {
      if (elem.getAttribute("name") !== null) {
        obj[elem.getAttribute("name")] = elem.innerText;
      }
      return null;
    });
    const newArrIndex = Add.findIndex((item) => {
      if (item.Reference === Reference) {
        return item;
      } else {
        return null;
      }
    });
    Add[newArrIndex] = obj;
    setAdd(Add);
  };

  const handleAddFormSubmit = (event) => {
    event.preventDefault();

    let newContact = {
      Reference: `000${Add.length + 1}`,
      Name: addFormData.Name,
      Age: addFormData.Age,
      Company: addFormData.Company,
      Country: addFormData.Country,
      City: addFormData.City,
    };
    const newContacts = [...Add, newContact];
    setAdd(newContacts);
  };

  const handleAddInput = () => {
    setInputs([...inputs, { fullName: "", phone: "" }]);
  };

  const handleRemoveInput = (index) => {
    const newInputs = inputs.filter((_, i) => i !== index);
    setInputs(newInputs);
  };

  const handleInputChange = (index, field, value) => {
    const newInputs = [...inputs];
    newInputs[index][field] = value;
    setInputs(newInputs);
  };

  const handleAddCar = () => {
    setCars([...cars, { make: "", model: "", year: "", color: "", plate: "" }]);
  };

  const handleRemoveCar = (index) => {
    const newCars = cars.filter((_, i) => i !== index);
    setCars(newCars);
  };

  const handleInputCarChange = (index, field, value) => {
    const newCars = [...cars];
    newCars[index][field] = value;
    setCars(newCars);
  };

  return (
    <>
      <div id="content-page" className="content-inner">
        <Container className="position-relative p-0">
          <div
            className="header-cover-img"
            style={{
              backgroundImage: `url(${pageBgImg})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
        </Container>
        <Container>
          <Row>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Col sm={12}>
                <Card className="profile-box">
                  <Card.Body>
                    <Row className="align-items-center item-header-content">
                      <Col lg={4} className="profile-left"></Col>
                      <Col lg={4} className="text-center profile-center">
                        <div className="header-avatar position-relative d-inline-block">
                          <span className="change-profile-image bg-primary rounded-pill">
                            <span className="material-symbols-outlined text-white font-size-16">
                              photo_camera
                            </span>
                          </span>
                          <img
                            src={user1}
                            alt="user"
                            className="avatar-150 border border-4 border-white rounded-3"
                          />
                          <span className="badge bg-success fw-500 letter-spacing-1 chat-status">
                            online
                          </span>
                        </div>
                        <h5 className="d-flex align-items-center justify-content-center gap-1 mb-2">
                          Thomas Hadden{" "}
                          <span className="badge  bg-primary rounded-pill material-symbols-outlined font-size-14 p-0 custom-done">
                            done
                          </span>
                        </h5>
                        <ul className="d-flex align-items-center justify-content-center gap-3 list-inline p-0 m-0">
                          <li className="d-flex align-items-center gap-1">
                            <h6 className="material-symbols-outlined font-size-14">
                              location_on
                            </h6>
                            <span className="font-size-14 text-uppercase fw-500">
                              San Giovanni
                            </span>
                          </li>
                          <li className="d-flex align-items-center gap-1">
                            <h6 className="material-symbols-outlined font-size-14">
                              globe_asia
                            </h6>
                            <Link
                              to="#"
                              className="font-size-14 fw-500 text-body"
                            >
                              hadlotechnologies.com/
                            </Link>
                          </li>
                        </ul>
                      </Col>
                      <Col lg={4} className="profile-right"></Col>
                    </Row>
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Body className="p-0">
                    <div className="user-tabing item-list-tabs">
                      <Nav
                        as="ul"
                        variant="pills"
                        className="d-flex align-items-center justify-content-center profile-feed-items p-0 m-0 rounded"
                      >
                        <Nav.Item as="li" className=" col-12 col-sm-3">
                          <Nav.Link
                            href="#pills-timeline-tab"
                            eventKey="first"
                            role="button"
                            className=" d-flex flex-md-column align-items-center flex-row justify-content-center gap-2"
                          >
                            <span className="icon rounded-3">
                              <span className="material-symbols-outlined">
                                calendar_month
                              </span>
                            </span>
                            <p className="mb-0 mt-0 mt-md-3">Calendar</p>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" className="col-12 col-sm-3">
                          <Nav.Link
                            href="#pills-about-tab"
                            eventKey="second"
                            role="button"
                            className="d-flex flex-md-column align-items-center flex-row justify-content-center gap-2"
                          >
                            <span className="icon rounded-3">
                              <span className="material-symbols-outlined">
                                person
                              </span>
                            </span>{" "}
                            <p className="mb-0 mt-0 mt-md-3">Profile</p>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item
                          as="li"
                          className=" col-12 col-sm-3 "
                          onClick={() => navigate("/dashboard/app/bill-status")}
                        >
                          <Nav.Link
                            eventKey="third"
                            role="button"
                            className="d-flex flex-md-column align-items-center flex-row justify-content-center gap-2"
                          >
                            <span className="icon rounded-3">
                              <span className="material-symbols-outlined">
                                description
                              </span>
                            </span>
                            <p className="mb-0 mt-0 mt-md-3">Bill Status</p>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item
                          as="li"
                          className="col-12 col-sm-3"
                          onClick={() =>
                            navigate("/dashboard/app/arc-requests")
                          }
                        >
                          <Nav.Link
                            eventKey="forth"
                            role="button"
                            className="d-flex flex-md-column align-items-center flex-row justify-content-center gap-2"
                          >
                            <span className="icon rounded-3">
                              <span className="material-symbols-outlined">
                                assignment
                              </span>
                            </span>
                            <p className="mb-0 mt-0 mt-md-3">Requests Status</p>
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={12}>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <Card.Body className=" p-0">
                      <Row className="row-eq-height">
                        <Col md="4" lg="4">
                          <Card>
                            <Card.Header className="d-flex justify-content-between">
                              <div className="header-title">
                                <h4 className="card-title ">Classification</h4>
                              </div>
                              <div className="card-header-toolbar d-flex align-items-center">
                                <Link
                                  to="#"
                                  className="material-symbols-outlined"
                                >
                                  add
                                </Link>
                              </div>
                            </Card.Header>
                            <Card.Body className="pt-0">
                              <ul className="m-0 p-0 job-classification list-inline">
                                <li className="d-flex align-items-center">
                                  <i className="material-symbols-outlined md-18 bg-primary">
                                    check_circle
                                  </i>
                                  HOA Meeting
                                </li>
                                <li className="d-flex align-items-center">
                                  <i className="material-symbols-outlined md-18 bg-success">
                                    check_circle
                                  </i>
                                  Voting
                                </li>
                                <li className="d-flex align-items-center">
                                  <i className="material-symbols-outlined md-18 bg-warning">
                                    check_circle
                                  </i>
                                  Bills
                                </li>
                                <li className="d-flex align-items-center">
                                  <i className="material-symbols-outlined md-18 bg-info">
                                    check_circle
                                  </i>
                                  Violation
                                </li>
                                <li className="d-flex align-items-center">
                                  <i className="material-symbols-outlined md-18 bg-secondary">
                                    check_circle
                                  </i>
                                  Event/News
                                </li>
                              </ul>
                            </Card.Body>
                          </Card>
                          <Card>
                            <Card.Header className="d-flex justify-content-between">
                              <div className="header-title">
                                <h4 className="card-title">Today's Schedule</h4>
                              </div>
                            </Card.Header>
                            <Card.Body className="pt-0">
                              <ul className="m-0 p-0 today-schedule">
                                <li className="d-flex">
                                  <div className="schedule-icon">
                                    <i className="material-symbols-outlined text-primary md-18">
                                      fiber_manual_record
                                    </i>
                                  </div>
                                  <div className="schedule-text">
                                    <span>Web Design</span>
                                    <span>09:00 to 12:00</span>
                                  </div>
                                </li>
                                <li className="d-flex">
                                  <div className="schedule-icon">
                                    <i className="material-symbols-outlined text-success md-18">
                                      fiber_manual_record
                                    </i>
                                  </div>
                                  <div className="schedule-text">
                                    <span>Participate in Design</span>
                                    <span>09:00 to 12:00</span>
                                  </div>
                                </li>
                              </ul>
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md="8" lg="8">
                          <Card>
                            <Card.Header className="d-flex justify-content-between flex-wrap">
                              <div className="header-title">
                                <h4 className="card-title">My Calendar</h4>
                              </div>
                            </Card.Header>
                            <Card.Body className="pt-0">
                              <FullCalendar
                                className="calendar-s"
                                initialView="dayGridMonth"
                                contentHeight="auto"
                                eventLimit=" true"
                                dayMaxEvents={1}
                                plugins={[
                                  dayGridPlugin,
                                  listPlugin,
                                  bootstrapPlugin,
                                ]}
                                headerToolbar={{
                                  left: "prev,next today",
                                  center: "title",
                                  right:
                                    "dayGridMonth,dayGridWeek,dayGridDay,listWeek",
                                }}
                                events={events}
                              />
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="about1"
                    >
                      <Row>
                        <Col sm="12">
                          <Card>
                            <Card.Body>
                              <Tab.Content>
                                <div className="d-flex justify-content-between">
                                  <h6 className="text-primary">
                                    Account number: BVE1036V
                                  </h6>
                                  <div className="edit-relation ">
                                    <Link
                                      to="#"
                                      className="d-flex align-items-center"
                                    >
                                      <span className="material-symbols-outlined me-2 md-12">
                                        edit
                                      </span>
                                      Edit
                                    </Link>
                                  </div>
                                </div>
                                <hr />

                                <Card.Body>
                                  <Accordion
                                    defaultActiveKey={["0"]}
                                    alwaysOpen
                                    className="gap-2"
                                  >
                                    <Accordion.Item
                                      className="mb-3"
                                      eventKey="0"
                                    >
                                      <Accordion.Header id="heading1">
                                        Owner/Renter Profile
                                      </Accordion.Header>
                                      <Accordion.Body className="bg-light-subtle">
                                        <Row>
                                          <Col lg={10}>
                                            <Row className="form-group align-items-center">
                                              <label className="control-label col-sm-2 align-self-center mb-0">
                                                Title
                                              </label>
                                              <div className="col-sm-3">
                                                <select
                                                  className="form-select"
                                                  id="title"
                                                  name="title"
                                                >
                                                  <option defaultValue="-">
                                                    -
                                                  </option>
                                                  <option value="Mr.">
                                                    Mr.
                                                  </option>
                                                  <option value="Ms.">
                                                    Ms.
                                                  </option>
                                                </select>
                                              </div>
                                              <div className="col-sm-3">
                                                <Form.Check
                                                  label="Set as Main Homeowner"
                                                  border="primary"
                                                />
                                              </div>
                                            </Row>
                                            <Row className="form-group align-items-center">
                                              <label className="control-label col-sm-2 align-self-center mb-0">
                                                Full name
                                              </label>
                                              <div className="col-sm-4">
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  id="firstName"
                                                  name="firstName"
                                                  placeholder="First name.."
                                                  required
                                                />
                                              </div>
                                              <div className="col-sm-4 flex flex-row gap-2 align-items-center">
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  id="lastName"
                                                  name="lastName"
                                                  placeholder="Last name.."
                                                  required
                                                />
                                              </div>
                                            </Row>
                                            <Row className="form-group align-items-center">
                                              <label className="control-label col-sm-2 align-self-center mb-0">
                                                Email Address
                                              </label>
                                              <div className="col-sm-4">
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  id="email1"
                                                  name="email1"
                                                  placeholder="Email Address 1"
                                                  required
                                                />
                                              </div>
                                              <div className="col-sm-4">
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  id="email2"
                                                  name="email2"
                                                  placeholder="Email Address 2"
                                                />
                                              </div>
                                            </Row>
                                            <Row className="form-group align-items-center">
                                              <label className="control-label col-sm-2 align-self-center mb-0">
                                                Phone Number
                                              </label>
                                              <div className="col-sm-4">
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  id="phone1"
                                                  name="phone1"
                                                  placeholder="Phone Number 1"
                                                  required
                                                />
                                              </div>
                                              <div className="col-sm-4">
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  id="phone2"
                                                  name="phone2"
                                                  placeholder="Phone Number 2"
                                                />
                                              </div>
                                            </Row>
                                            <Row className="form-group align-items-center">
                                              <label className="control-label col-sm-2 align-self-center mb-0">
                                                Birthday
                                              </label>
                                              <div className="col-sm-4">
                                                <input
                                                  type="date"
                                                  className="form-control"
                                                  id="birthDate"
                                                  name="birthDate"
                                                  placeholder="-"
                                                  required
                                                />
                                              </div>
                                            </Row>
                                          </Col>
                                          <Col lg={2} className="my-auto">
                                            <div className="d-flex flex-column align-items-center justify-content-center">
                                              <div>
                                                <img
                                                  className="img-fluid"
                                                  alt="user"
                                                  loading="lazy"
                                                  src={user01}
                                                />
                                              </div>
                                              <div className="d-flex flex-row gap-3 mt-4">
                                                <div className="d-flex align-items-center">
                                                  <label
                                                    htmlFor="userPicture"
                                                    className="d-flex align-items-center"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    <i className="material-symbols-outlined">
                                                      attach_file
                                                    </i>
                                                  </label>
                                                  <input
                                                    id="userPicture"
                                                    name="userPicture"
                                                    type="file"
                                                    accept="image/*"
                                                    className="d-none"
                                                    // onChange={(e) =>
                                                    //   handleAttachPicture(e)
                                                    // }
                                                  />
                                                </div>
                                                <button
                                                  type="button"
                                                  className="border-0 bg-transparent d-flex align-items-center text-danger"
                                                  // onClick={() =>
                                                  //   setAttachedPicture(null)
                                                  // }
                                                >
                                                  <i className="material-symbols-outlined">
                                                    delete
                                                  </i>
                                                </button>
                                              </div>
                                            </div>
                                          </Col>
                                        </Row>
                                      </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item
                                      className="mb-3"
                                      eventKey="1"
                                    >
                                      <Accordion.Header id="heading2">
                                        Co-owner
                                      </Accordion.Header>
                                      <Accordion.Body className="bg-light-subtle">
                                        <Row>
                                          <Col lg={10}>
                                            <Row className="form-group align-items-center">
                                              <label className="control-label col-sm-2 align-self-center mb-0">
                                                Title
                                              </label>
                                              <div className="col-sm-3">
                                                <select
                                                  className="form-select"
                                                  id="title"
                                                  name="title"
                                                >
                                                  <option defaultValue="-">
                                                    -
                                                  </option>
                                                  <option value="Mr.">
                                                    Mr.
                                                  </option>
                                                  <option value="Ms.">
                                                    Ms.
                                                  </option>
                                                </select>
                                              </div>
                                              <div className="col-sm-3">
                                                <Form.Check
                                                  label="Set as Main Homeowner"
                                                  border="primary"
                                                />
                                              </div>
                                            </Row>
                                            <Row className="form-group align-items-center">
                                              <label className="control-label col-sm-2 align-self-center mb-0">
                                                Full name
                                              </label>
                                              <div className="col-sm-4">
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  id="coownerFirstName"
                                                  name="coownerFirstName"
                                                  placeholder="First name.."
                                                  required
                                                />
                                              </div>
                                              <div className="col-sm-4 flex flex-row gap-2 align-items-center">
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  id="coownerLastName"
                                                  name="coownerLastName"
                                                  placeholder="Last name.."
                                                  required
                                                />
                                              </div>
                                            </Row>
                                            <Row className="form-group align-items-center">
                                              <label className="control-label col-sm-2 align-self-center mb-0">
                                                Email Address
                                              </label>
                                              <div className="col-sm-4">
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  id="coownerEmail1"
                                                  name="coownerEmail1"
                                                  placeholder="Email Address 1"
                                                  required
                                                />
                                              </div>
                                              <div className="col-sm-4">
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  id="coownerEmail2"
                                                  name="coownerEmail2"
                                                  placeholder="Email Address 2"
                                                />
                                              </div>
                                            </Row>
                                            <Row className="form-group align-items-center">
                                              <label className="control-label col-sm-2 align-self-center mb-0">
                                                Phone Number
                                              </label>
                                              <div className="col-sm-4">
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  id="coownerPhone1"
                                                  name="coownerPhone1"
                                                  placeholder="Phone Number 1"
                                                  required
                                                />
                                              </div>
                                              <div className="col-sm-4">
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  id="coownerPhone2"
                                                  name="coownerPhone2"
                                                  placeholder="Phone Number 2"
                                                />
                                              </div>
                                            </Row>
                                            <Row className="form-group align-items-center">
                                              <label className="control-label col-sm-2 align-self-center mb-0">
                                                Birthday
                                              </label>
                                              <div className="col-sm-4">
                                                <input
                                                  type="date"
                                                  className="form-control"
                                                  id="coownerBirthDate"
                                                  name="coownerBirthDate"
                                                  placeholder="-"
                                                  required
                                                />
                                              </div>
                                            </Row>
                                          </Col>
                                          <Col lg={2} className="my-auto">
                                            <div className="d-flex flex-column align-items-center justify-content-center">
                                              <div>
                                                <img
                                                  className="img-fluid"
                                                  alt="user"
                                                  loading="lazy"
                                                  src={user01}
                                                />
                                              </div>
                                              <div className="d-flex flex-row gap-3 mt-4">
                                                <div className="d-flex align-items-center">
                                                  <label
                                                    htmlFor="userPicture"
                                                    className="d-flex align-items-center"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    <i className="material-symbols-outlined">
                                                      attach_file
                                                    </i>
                                                  </label>
                                                  <input
                                                    id="userPicture"
                                                    name="userPicture"
                                                    type="file"
                                                    accept="image/*"
                                                    className="d-none"
                                                    // onChange={(e) =>
                                                    //   handleAttachPicture(e)
                                                    // }
                                                  />
                                                </div>
                                                <button
                                                  type="button"
                                                  className="border-0 bg-transparent d-flex align-items-center text-danger"
                                                  // onClick={() =>
                                                  //   setAttachedPicture(null)
                                                  // }
                                                >
                                                  <i className="material-symbols-outlined">
                                                    delete
                                                  </i>
                                                </button>
                                              </div>
                                            </div>
                                          </Col>
                                        </Row>
                                      </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item
                                      className="mb-3"
                                      eventKey="3"
                                    >
                                      <Accordion.Header id="heading3">
                                        Roles
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        <Row>
                                          <Col lg={6}>
                                            <Row className="form-group align-items-center">
                                              <label className="control-label col-sm-2 align-self-center mb-0">
                                                Roles
                                              </label>

                                              <div className="form-check col-sm-2 px-5 py-3 mx-2 border border-light d-flex justify-content-center align-items-center rounded-3">
                                                <input
                                                  className="border border-primary"
                                                  type="checkbox"
                                                  name="role"
                                                  id="role"
                                                />
                                                <label
                                                  className="form-check-label ms-2 d-flex flex-column gap-2 align-items-center"
                                                  htmlFor="role"
                                                >
                                                  <svg
                                                    width="29"
                                                    height="30"
                                                    viewBox="0 0 29 30"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                  >
                                                    <g clipPath="url(#clip0_2342_8352)">
                                                      <path
                                                        d="M6.20898 7.5C6.20898 9.48912 7.05184 11.3968 8.55213 12.8033C10.0524 14.2098 12.0873 15 14.209 15C16.3307 15 18.3655 14.2098 19.8658 12.8033C21.3661 11.3968 22.209 9.48912 22.209 7.5C22.209 5.51088 21.3661 3.60322 19.8658 2.1967C18.3655 0.790176 16.3307 0 14.209 0C12.0873 0 10.0524 0.790176 8.55213 2.1967C7.05184 3.60322 6.20898 5.51088 6.20898 7.5ZM12.1152 19.2305L13.2777 21.0469L11.1965 28.3066L8.94648 19.6992C8.82148 19.2246 8.33398 18.9141 7.82773 19.0371C3.45273 20.0625 0.208984 23.7773 0.208984 28.2012C0.208984 29.1973 1.07148 30 2.12773 30H10.359C10.359 30 10.359 30 10.3652 30H10.709H17.709H18.0527C18.0527 30 18.0527 30 18.059 30H26.2902C27.3527 30 28.209 29.1914 28.209 28.2012C28.209 23.7773 24.9652 20.0625 20.5902 19.0371C20.084 18.9199 19.5965 19.2305 19.4715 19.6992L17.2215 28.3066L15.1402 21.0469L16.3027 19.2305C16.7027 18.6035 16.2215 17.8125 15.4465 17.8125H14.209H12.9777C12.2027 17.8125 11.7215 18.6094 12.1215 19.2305H12.1152Z"
                                                        fill="#6C757D"
                                                      />
                                                    </g>
                                                    <defs>
                                                      <clipPath id="clip0_2342_8352">
                                                        <rect
                                                          width="28"
                                                          height="30"
                                                          fill="white"
                                                          transform="translate(0.208984)"
                                                        />
                                                      </clipPath>
                                                    </defs>
                                                  </svg>
                                                  <span>Admin</span>
                                                </label>
                                              </div>
                                              <div className="form-check col-sm-2 px-5 py-3 mx-2 border border-light d-flex justify-content-center align-items-center rounded-3">
                                                <input
                                                  className="border border-primary"
                                                  type="checkbox"
                                                  name="role"
                                                  id="role"
                                                />
                                                <label
                                                  className="form-check-label ms-2 d-flex flex-column gap-2 align-items-center"
                                                  htmlFor="role"
                                                >
                                                  <svg
                                                    width="29"
                                                    height="30"
                                                    viewBox="0 0 29 30"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                  >
                                                    <g clipPath="url(#clip0_2342_8352)">
                                                      <path
                                                        d="M6.20898 7.5C6.20898 9.48912 7.05184 11.3968 8.55213 12.8033C10.0524 14.2098 12.0873 15 14.209 15C16.3307 15 18.3655 14.2098 19.8658 12.8033C21.3661 11.3968 22.209 9.48912 22.209 7.5C22.209 5.51088 21.3661 3.60322 19.8658 2.1967C18.3655 0.790176 16.3307 0 14.209 0C12.0873 0 10.0524 0.790176 8.55213 2.1967C7.05184 3.60322 6.20898 5.51088 6.20898 7.5ZM12.1152 19.2305L13.2777 21.0469L11.1965 28.3066L8.94648 19.6992C8.82148 19.2246 8.33398 18.9141 7.82773 19.0371C3.45273 20.0625 0.208984 23.7773 0.208984 28.2012C0.208984 29.1973 1.07148 30 2.12773 30H10.359C10.359 30 10.359 30 10.3652 30H10.709H17.709H18.0527C18.0527 30 18.0527 30 18.059 30H26.2902C27.3527 30 28.209 29.1914 28.209 28.2012C28.209 23.7773 24.9652 20.0625 20.5902 19.0371C20.084 18.9199 19.5965 19.2305 19.4715 19.6992L17.2215 28.3066L15.1402 21.0469L16.3027 19.2305C16.7027 18.6035 16.2215 17.8125 15.4465 17.8125H14.209H12.9777C12.2027 17.8125 11.7215 18.6094 12.1215 19.2305H12.1152Z"
                                                        fill="#6C757D"
                                                      />
                                                    </g>
                                                    <defs>
                                                      <clipPath id="clip0_2342_8352">
                                                        <rect
                                                          width="28"
                                                          height="30"
                                                          fill="white"
                                                          transform="translate(0.208984)"
                                                        />
                                                      </clipPath>
                                                    </defs>
                                                  </svg>
                                                  <span>Board</span>
                                                </label>
                                              </div>
                                              <div className="form-check col-sm-2 px-5 py-3 mx-2 border border-light d-flex justify-content-center align-items-center rounded-3">
                                                <input
                                                  className="border border-primary"
                                                  type="checkbox"
                                                  name="role"
                                                  id="role"
                                                />
                                                <label
                                                  className="form-check-label ms-2 d-flex flex-column gap-2 align-items-center"
                                                  htmlFor="role"
                                                >
                                                  <svg
                                                    width="29"
                                                    height="30"
                                                    viewBox="0 0 29 30"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                  >
                                                    <g clipPath="url(#clip0_2342_8352)">
                                                      <path
                                                        d="M6.20898 7.5C6.20898 9.48912 7.05184 11.3968 8.55213 12.8033C10.0524 14.2098 12.0873 15 14.209 15C16.3307 15 18.3655 14.2098 19.8658 12.8033C21.3661 11.3968 22.209 9.48912 22.209 7.5C22.209 5.51088 21.3661 3.60322 19.8658 2.1967C18.3655 0.790176 16.3307 0 14.209 0C12.0873 0 10.0524 0.790176 8.55213 2.1967C7.05184 3.60322 6.20898 5.51088 6.20898 7.5ZM12.1152 19.2305L13.2777 21.0469L11.1965 28.3066L8.94648 19.6992C8.82148 19.2246 8.33398 18.9141 7.82773 19.0371C3.45273 20.0625 0.208984 23.7773 0.208984 28.2012C0.208984 29.1973 1.07148 30 2.12773 30H10.359C10.359 30 10.359 30 10.3652 30H10.709H17.709H18.0527C18.0527 30 18.0527 30 18.059 30H26.2902C27.3527 30 28.209 29.1914 28.209 28.2012C28.209 23.7773 24.9652 20.0625 20.5902 19.0371C20.084 18.9199 19.5965 19.2305 19.4715 19.6992L17.2215 28.3066L15.1402 21.0469L16.3027 19.2305C16.7027 18.6035 16.2215 17.8125 15.4465 17.8125H14.209H12.9777C12.2027 17.8125 11.7215 18.6094 12.1215 19.2305H12.1152Z"
                                                        fill="#6C757D"
                                                      />
                                                    </g>
                                                    <defs>
                                                      <clipPath id="clip0_2342_8352">
                                                        <rect
                                                          width="28"
                                                          height="30"
                                                          fill="white"
                                                          transform="translate(0.208984)"
                                                        />
                                                      </clipPath>
                                                    </defs>
                                                  </svg>
                                                  <span>Sub</span>
                                                </label>
                                              </div>
                                            </Row>
                                          </Col>
                                          <Col lg={6}>
                                            <Row>
                                              <label className="control-label align-self-center">
                                                Sub-Committee
                                              </label>
                                              <Form.Group className="form-group">
                                                <select
                                                  className="form-select"
                                                  id="exampleFormControlSelect1"
                                                >
                                                  <option>ARC</option>
                                                  <option>Option 1</option>
                                                  <option>Option 2</option>
                                                  <option>Option 3</option>
                                                  <option>Option 4</option>
                                                  <option>Option 5</option>
                                                </select>
                                              </Form.Group>
                                            </Row>
                                          </Col>
                                        </Row>
                                      </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item
                                      className="mb-3"
                                      eventKey="4"
                                    >
                                      <Accordion.Header id="heading4">
                                        Households
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        <Button
                                          variant="outline-primary"
                                          onClick={handleAddInput}
                                          className="mb-3"
                                        >
                                          <FaPlus /> Add
                                        </Button>
                                        {inputs.map((input, index) => (
                                          <Row
                                            key={index}
                                            className="mb-2 align-items-center"
                                          >
                                            <Col md={5}>
                                              <Form.Group
                                                controlId={`fullName-${index}`}
                                              >
                                                <Form.Label>
                                                  Full Name
                                                </Form.Label>
                                                <Form.Control
                                                  type="text"
                                                  value={input.fullName}
                                                  onChange={(e) =>
                                                    handleInputChange(
                                                      index,
                                                      "fullName",
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </Form.Group>
                                            </Col>
                                            <Col md={5}>
                                              <Form.Group
                                                controlId={`phone-${index}`}
                                              >
                                                <Form.Label>Phone</Form.Label>
                                                <InputGroup>
                                                  <Form.Control
                                                    type="text"
                                                    value={input.phone}
                                                    onChange={(e) =>
                                                      handleInputChange(
                                                        index,
                                                        "phone",
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                  <Button
                                                    variant="outline-danger"
                                                    onClick={() =>
                                                      handleRemoveInput(index)
                                                    }
                                                  >
                                                    <FaTrash />
                                                  </Button>
                                                </InputGroup>
                                              </Form.Group>
                                            </Col>
                                          </Row>
                                        ))}
                                      </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item
                                      className="mb-3"
                                      eventKey="5"
                                    >
                                      <Accordion.Header id="heading5">
                                        Cars
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        <Button
                                          variant="outline-primary"
                                          onClick={handleAddCar}
                                          className="mb-3"
                                        >
                                          <FaPlus /> Add
                                        </Button>
                                        {cars.map((car, index) => (
                                          <Row
                                            key={index}
                                            className="mb-2 align-items-center"
                                          >
                                            <Col md={2}>
                                              <Form.Group
                                                controlId={`make-${index}`}
                                              >
                                                <Form.Label>Make</Form.Label>
                                                <Form.Control
                                                  type="text"
                                                  value={car.make}
                                                  onChange={(e) =>
                                                    handleInputChange(
                                                      index,
                                                      "make",
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </Form.Group>
                                            </Col>
                                            <Col md={2}>
                                              <Form.Group
                                                controlId={`model-${index}`}
                                              >
                                                <Form.Label>Model</Form.Label>
                                                <Form.Control
                                                  type="text"
                                                  value={car.model}
                                                  onChange={(e) =>
                                                    handleInputChange(
                                                      index,
                                                      "model",
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </Form.Group>
                                            </Col>
                                            <Col md={2}>
                                              <Form.Group
                                                controlId={`year-${index}`}
                                              >
                                                <Form.Label>Year</Form.Label>
                                                <Form.Control
                                                  type="text"
                                                  value={car.year}
                                                  onChange={(e) =>
                                                    handleInputChange(
                                                      index,
                                                      "year",
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </Form.Group>
                                            </Col>
                                            <Col md={2}>
                                              <Form.Group
                                                controlId={`color-${index}`}
                                              >
                                                <Form.Label>Color</Form.Label>
                                                <Form.Control
                                                  type="text"
                                                  value={car.color}
                                                  onChange={(e) =>
                                                    handleInputChange(
                                                      index,
                                                      "color",
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </Form.Group>
                                            </Col>
                                            <Col md={2}>
                                              <Form.Group
                                                controlId={`plate-${index}`}
                                              >
                                                <Form.Label>Plate</Form.Label>
                                                <InputGroup>
                                                  <Form.Control
                                                    type="text"
                                                    value={car.plate}
                                                    onChange={(e) =>
                                                      handleInputChange(
                                                        index,
                                                        "plate",
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                  <Button
                                                    variant="outline-danger"
                                                    onClick={() =>
                                                      handleRemoveCar(index)
                                                    }
                                                  >
                                                    <FaTrash />
                                                  </Button>
                                                </InputGroup>
                                              </Form.Group>
                                            </Col>
                                          </Row>
                                        ))}
                                      </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item
                                      className="mb-3"
                                      eventKey="6"
                                    >
                                      <Accordion.Header id="heading6">
                                        Listing and Notification
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        <Row>
                                          <Col>
                                            <Form>
                                              <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                label="Telephone Unlisted"
                                              />
                                              <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                label="Profile Unlisted"
                                              />
                                            </Form>
                                          </Col>
                                          <Col>
                                            <Form>
                                              <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                label="Two Factor Security"
                                              />
                                              <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                label="SMS"
                                              />
                                            </Form>
                                          </Col>
                                          <Col>
                                            <Form>
                                              <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                label="Email"
                                              />
                                            </Form>
                                          </Col>
                                        </Row>
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  </Accordion>
                                </Card.Body>
                              </Tab.Content>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    </Tab.Container>
                  </Tab.Pane>
                  <Tab.Pane eventKey="third">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="all-friends"
                    >
                      <Card>
                        <Row>
                          <Col sm="12">
                            <Card>
                              <div>
                                <h3 className="card-header text-center font-weight-bold text-uppercase py-4">
                                  Bill Status
                                </h3>
                              </div>
                              <Card.Body className="pt-0">
                                <div
                                  Reference="table"
                                  className="table-editable"
                                >
                                  <table className="table table-bordered table-responsive-md table-striped text-center">
                                    <thead>
                                      <tr>
                                        <th>Control #</th>
                                        <th>Description</th>
                                        <th>Date Issued</th>
                                        <th>Date Due</th>
                                        <th>Amount</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {data.map((item, idx) => (
                                        <tr key={idx}>
                                          <td>{item.control}</td>
                                          <td>{item.Description}</td>
                                          <td>{item.DateIssued}</td>
                                          <td>{item.DateDue}</td>
                                          <td>{item.Amount}</td>
                                          <td>{item.Status}</td>

                                          <td>
                                            <a href="">
                                              <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip>Pay</Tooltip>}
                                              >
                                                <i className="material-symbols-outlined me-1 md-18 text-primary">
                                                  paid
                                                </i>
                                              </OverlayTrigger>{" "}
                                            </a>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                      </Card>
                    </Tab.Container>
                  </Tab.Pane>
                  <Tab.Pane eventKey="forth">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="p1">
                      <Card>
                        <div>
                          <h3 className="card-header text-center font-weight-bold text-uppercase py-4">
                            Arc & Other Requests lists
                          </h3>
                        </div>
                        <Card.Body className="pt-0">
                          <div Reference="table" className="table-editable">
                            <span className="table-add float-end mb-3 me-2">
                              <Button className="btn btn-sm btn-success d-flex align-items-center">
                                <i
                                  className="material-symbols-outlined me-1 md-18"
                                  onClick={addNewValue}
                                >
                                  add
                                </i>
                                Create New
                              </Button>
                            </span>
                            <table className="table table-bordered table-responsive-md table-striped text-center">
                              <thead>
                                <tr>
                                  <th>Reference</th>
                                  <th>Created by</th>
                                  <th>Type</th>
                                  <th>Description</th>
                                  <th>Date created </th>
                                  <th>Date closed</th>
                                  <th>Status</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {Add.map((adds, props) => (
                                  <tr
                                    key={props}
                                    dataReference={adds.Reference}
                                  >
                                    <td
                                      name="Reference"
                                      contentEditable={true}
                                      suppressContentEditableWarning={true}
                                      onBlur={handleAddFormChanges}
                                    >
                                      {adds.Reference}
                                    </td>
                                    <td
                                      name="CreatedBy"
                                      contentEditable={true}
                                      suppressContentEditableWarning={true}
                                      onBlur={handleAddFormChanges}
                                    >
                                      {adds.CreatedBy}
                                    </td>
                                    <td
                                      name="Type"
                                      contentEditable={true}
                                      suppressContentEditableWarning={true}
                                      onBlur={handleAddFormChanges}
                                    >
                                      {adds.Type}
                                    </td>
                                    <td
                                      name="Description"
                                      contentEditable={true}
                                      suppressContentEditableWarning={true}
                                      onBlur={handleAddFormChanges}
                                    >
                                      {adds.Description}
                                    </td>
                                    <td
                                      name="DateCreated"
                                      contentEditable={true}
                                      suppressContentEditableWarning={true}
                                      onBlur={handleAddFormChanges}
                                    >
                                      {adds.DateCreated}
                                    </td>
                                    <td
                                      name="DateClosed"
                                      contentEditable={true}
                                      suppressContentEditableWarning={true}
                                      onBlur={handleAddFormChanges}
                                    >
                                      {adds.DateClosed}
                                    </td>
                                    <td
                                      name="Status"
                                      contentEditable={true}
                                      suppressContentEditableWarning={true}
                                      onBlur={handleAddFormChanges}
                                    >
                                      {adds.Status}
                                    </td>

                                    <td>
                                      <a href="">
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={<Tooltip>Transact</Tooltip>}
                                        >
                                          <i className="material-symbols-outlined me-1 md-18 text-primary">
                                            assignment
                                          </i>
                                        </OverlayTrigger>{" "}
                                      </a>
                                      <a href="">
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={<Tooltip>View</Tooltip>}
                                        >
                                          <i className="material-symbols-outlined me-1 md-18 text-primary">
                                            visibility
                                          </i>
                                        </OverlayTrigger>{" "}
                                      </a>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </Card.Body>
                      </Card>
                    </Tab.Container>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Tab.Container>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default UserProfile;
