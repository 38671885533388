import React, { useState, useRef, ChangeEvent } from "react";
import {
  Col,
  Card,
  Dropdown,
  OverlayTrigger,
  Tooltip,
  Collapse,
  Form,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import ShareOffcanvasNew from "../../components/ShareOffcanvasNew";
import {
  EventAttendeeModel,
  EventCommentModel,
  EventItemServiceModel,
  EventModel,
  SavePledgeBody,
} from "../../model/event/eventModel";
import ReactTimeAgo from "react-time-ago";
import {
  useSaveEventAttendeeMutation,
  useSaveEventCommentMutation,
  useSaveEventPledgeMutation,
} from "../../store/query/event/eventquery";
import { errorDisplayOrNavigate } from "../../utilities/notificationToast";

const user4 = require("../../assets/images/user/04.jpg");
const user1 = require("../../assets/images/user/1.jpg");
const user01 = require("../../assets/images/user/01.jpg");
const user2 = require("../../assets/images/user/02.jpg");
const user3 = require("../../assets/images/user/03.jpg");
const user5 = require("../../assets/images/page-img/fun.jpg");
const icon1 = require("../../assets/images/icon/01.png");
const icon2 = require("../../assets/images/icon/02.png");
const icon3 = require("../../assets/images/icon/03.png");
const icon4 = require("../../assets/images/icon/04.png");
const icon5 = require("../../assets/images/icon/05.png");
const icon6 = require("../../assets/images/icon/06.png");
const icon7 = require("../../assets/images/icon/07.png");

type Props = {
  data: EventModel;
};

interface PledgeDataModel {
  from: "item" | "service";
  data: EventItemServiceModel;
}

interface SelectedPledgeModel {
  id: number;
  name: string;
  quantity?: number;
  from: "item" | "service";
  error?: "";
  origQuantity?: number;
}

const filterAttendee = (data: EventModel): EventAttendeeModel[] => {
  let i = data.attendeeCount >= 4 ? 3 : data.attendeeCount - 1;
  const currentArray = [...data.attendees];
  currentArray.reverse();
  let newArray: EventAttendeeModel[] = [];

  currentArray.forEach((item, key) => {
    if (key <= i) {
      newArray.push(item);
    }
  });
  return newArray;
};

const userDataLocal = localStorage.getItem("savedAuthUserData");

const isAttendCheck = (data: EventModel): boolean => {
  const jsonUserData = JSON.parse(userDataLocal);
  const dataFind = data.attendees.find(
    (item) => item.user.picture === jsonUserData.picture
  );

  console.log(dataFind);

  if (dataFind) {
    return true;
  }

  return false;
};

const EventFeedItem = ({ data }: Props) => {
  const attendeeRef = useRef<EventAttendeeModel[]>(
    data.attendees.length > 0 ? filterAttendee(data) : []
  );
  const commentInputRef = useRef<HTMLInputElement>();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const [commentValue, setCommentValue] = useState<string>("");
  const [commentsData, setCommentsData] = useState<
    Partial<EventCommentModel>[]
  >(data ? data.comments : []);
  const [volunteerCount, setVolunteerCount] = useState<number>(
    data.volunteerCount
  );
  const [isAttend, setAttend] = useState<boolean>(isAttendCheck(data));
  const [selectedPledge, setSelectedPledge] = useState<SelectedPledgeModel[]>(
    []
  );

  const [
    saveCommmentMutate,
    {
      isLoading: saveCommentLoading,
      reset: saveCommentReset,
      error: saveCommentError,
    },
  ] = useSaveEventCommentMutation();

  const [
    saveAttendMutate,
    {
      isLoading: saveAttendLoading,
      reset: saveAttendReset,
      error: saveAttendError,
    },
  ] = useSaveEventAttendeeMutation();

  const [
    savePledgeMutate,
    {
      isLoading: savePledgeLoading,
      reset: savePledgeReset,
      error: savePledgeError,
    },
  ] = useSaveEventPledgeMutation();

  const handleSelectPledge = (e: ChangeEvent<HTMLSelectElement>) => {
    const valueNum = Number(e.target.value);

    if (valueNum !== 0) {
      const selectedPledgeFind = pledgeData.find(
        (item) => item.data.id === valueNum
      );
      setSelectedPledge((prev) => [
        ...prev,
        {
          id: valueNum,
          from: selectedPledgeFind.from,
          name: selectedPledgeFind.data.name,
          origQuantity: selectedPledgeFind.data.quantity,
        },
      ]);
    }
  };

  const handleSetQuantity = (value: string, index: number) => {
    let pledgeIns = [...selectedPledge];
    pledgeIns[index].quantity = Number(value);

    setSelectedPledge(pledgeIns);
  };

  const handleRemovePledge = (index: number) => {
    const pledgeIns = selectedPledge.filter((item, i) => i !== index);
    setSelectedPledge(pledgeIns);
  };

  const handletogglecommentpledge = (isComment: boolean) => {
    if (isComment) {
      if (open2) {
        setOpen2(false);
        setTimeout(() => {
          setOpen((prev) => !prev);
        }, 500);
      } else {
        setOpen((prev) => !prev);
      }
    } else {
      if (open) {
        setOpen(false);
        setTimeout(() => {
          setOpen2((prev) => !prev);
        }, 500);
      } else {
        setOpen2((prev) => !prev);
      }
    }
  };

  const pledgeData: PledgeDataModel[] = [
    ...data.itemsNeeded.map(
      (item): PledgeDataModel => ({ from: "item", data: item })
    ),
    ...data.servicesNeeded.map(
      (item): PledgeDataModel => ({ from: "service", data: item })
    ),
  ];

  const handleCommentChange = (e) => {
    setCommentValue(e.currentTarget.value);
  };

  const handleSaveComment = async () => {
    try {
      await saveCommmentMutate({ eventId: data.id, comment: commentValue });
      commentInputRef.current.value = "";
      setCommentsData((prev) => [{ comment: commentValue }, ...prev]);
    } catch (e) {
      console.log(e);
    }
  };

  const handleSavePledge = async () => {
    const itemPledges = selectedPledge.filter((item) => item.from === "item");
    const servicepledges = selectedPledge.filter(
      (item) => item.from === "service"
    );
    try {
      await savePledgeMutate({
        servicePledges: servicepledges.map((item) => ({
          eventNeededId: item.id,
          pledge: true,
        })),
        itemPledges: itemPledges.map((item) => ({
          eventNeededId: item.id,
          quantity: item.quantity,
        })),
      });

      setSelectedPledge([]);
    } catch (e) {
      console.log(e);
    }
  };

  const handleSaveAttendee = async () => {
    try {
      await saveAttendMutate({ eventId: data.id, attend: !isAttend });
      setAttend((prev) => !prev);
    } catch (e) {
      console.log(e);
    }
  };

  if (savePledgeError) {
    errorDisplayOrNavigate({
      error: savePledgeError,
      toastId: "pledge-error",
    });

    savePledgeReset();
  }

  if (saveCommentError) {
    errorDisplayOrNavigate({
      error: saveCommentError,
      toastId: "commnent-error",
    });

    saveCommentReset();
  }

  if (saveAttendError) {
    errorDisplayOrNavigate({
      error: saveAttendError,
      toastId: "attendee-error",
    });

    saveAttendReset();
  }

  return (
    <div>
      <Col sm={12} className="special-post">
        <Card className=" card-block card-stretch card-height">
          <Card.Body>
            <div className="user-post-data">
              <div className="d-flex align-items-center justify-content-between">
                <div className="me-3 flex-shrik-0">
                  <img
                    className="border border-2 rounded-circle user-post-profile"
                    src={data.picture}
                    alt=""
                  />
                </div>
                <div className="w-100">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <h6 className="mb-0 d-inline-block">{`${data.name}`}</h6>{" "}
                      <span className="d-inline-block text-primary">
                        <svg
                          className="align-text-bottom"
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M11.8457 0H4.34822C1.73547 0 0.0974121 1.84995 0.0974121 4.46789V11.5321C0.0974121 14.1501 1.72768 16 4.34822 16H11.8449C14.4663 16 16.0974 14.1501 16.0974 11.5321V4.46789C16.0974 1.84995 14.4663 0 11.8457 0Z"
                            fill="currentColor"
                          />
                          <path
                            d="M5.09741 7.99978L7.09797 9.9995L11.0974 6.00006"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>{" "}
                      <span className="mb-0 d-inline-block text-capitalize fw-medium">
                        posted an event
                      </span>
                      <p className="mb-0">
                        <ReactTimeAgo
                          date={new Date(data.createdAt)}
                          locale="en-US"
                        />
                      </p>
                    </div>

                    <div className="card-post-toolbar">
                      <Dropdown>
                        <Dropdown.Toggle id="post-option" as="span">
                          <span className="material-symbols-outlined">
                            more_horiz
                          </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="m-0 p-0">
                          <Dropdown.Item className=" p-3">
                            <div className="d-flex align-items-top">
                              <span className="material-symbols-outlined">
                                public
                              </span>
                              <div className="data ms-2">
                                <h6>Public</h6>
                                <p className="mb-0">All Homeowners</p>
                              </div>
                            </div>
                          </Dropdown.Item>

                          <Dropdown.Item className=" p-3">
                            <div className="d-flex align-items-top">
                              <span className="material-symbols-outlined">
                                admin_panel_settings
                              </span>
                              <div className="data ms-2">
                                <h6>Board</h6>
                                <p className="mb-0">Administrator & Board</p>
                              </div>
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item className=" p-3">
                            <div className="d-flex align-items-top">
                              <span className="material-symbols-outlined">
                                lock
                              </span>
                              <div className="data ms-2">
                                <h6>Only Menu</h6>
                                <p className="mb-0">Only Me</p>
                              </div>
                            </div>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <p className="m-0">{data.description}</p>
              {/* <ul className="list-inline m-0 p-0 d-flex flex-wrap gap-1">
                <li>
                  <Link to="#">#neighbours</Link>
                </li>
                <li>
                  <Link to="#">#xmas</Link>
                </li>
                <li>
                  <Link to="#">#boardevent</Link>
                </li>
                <li>
                  <Link to="#">#together</Link>
                </li>
                <li>
                  <Link to="#">#celebration</Link>
                </li>
              </ul> */}
            </div>
            <div className="user-post mt-4">
              <img
                src={data.picture}
                alt="post-images"
                className="img-fluid rounded w-100"
                loading="lazy"
              />
            </div>
            <div className="mt-4 pt-4 border-top">
              <div
                className={`d-flex ${
                  attendeeRef.current.length > 0
                    ? "justify-content-between"
                    : "justify-content-end"
                } align-items-center flex-wrap gap-4`}
              >
                {attendeeRef.current.length > 0 && (
                  <div className="d-flex align-items-center gap-2 flex-wrap">
                    <ul className="list-inline m-0 p-0 post-user-liked-list">
                      {attendeeRef.current.map((attendee, key) => (
                        <li key={key}>
                          <img
                            src={attendee.user.picture}
                            alt="userimg"
                            className="rounded-circle img-fluid userimg"
                            loading="lazy"
                          />
                        </li>
                      ))}
                    </ul>
                    <div className="d-inline-flex align-items-center gap-1">
                      <h6 className="m-0 font-size-14">
                        {attendeeRef.current[0].user.name}
                      </h6>
                      <span
                        className="text-capitalize font-size-14 fw-medium"
                        data-bs-toggle="modal"
                        data-bs-target="#likemodal"
                      >
                        {attendeeRef.current.length === 1
                          ? "will attend"
                          : `and ${data.attendeeCount - 1} ${
                              data.attendeeCount === 2 ? "other" : "others"
                            } will attend`}
                      </span>
                    </div>
                  </div>
                )}
                <button
                  className="btn btn-danger font-size-12 text-capitalize px-5"
                  onClick={handleSaveAttendee}
                >
                  {saveAttendLoading
                    ? "Saving..."
                    : isAttend
                    ? "Will not Attend"
                    : "Will Attend"}
                </button>
              </div>
            </div>
            <div className="comment-area mt-4 pt-4 border-top">
              <div className="d-flex justify-content-end align-items-center flex-wrap gap-4">
                <div className="d-flex align-items-center gap-3 flex-shrink-0">
                  <div
                    className="total-comment-block"
                    aria-controls="commentcollapes"
                    aria-expanded={open}
                    onClick={() => handletogglecommentpledge(false)}
                  >
                    <span className="material-symbols-outlined align-text-top font-size-20">
                      group
                    </span>{" "}
                    <span className="fw-medium">
                      {volunteerCount > 1
                        ? `${volunteerCount} Volunteers`
                        : `${volunteerCount} Volunteer`}
                    </span>
                  </div>
                  {/* 
                  <ShareOffcanvasNew
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                  /> */}
                </div>
                <div className="d-flex align-items-center gap-3 flex-shrink-0">
                  <div
                    className="total-comment-block"
                    aria-controls="commentcollapes"
                    aria-expanded={open}
                    onClick={() => handletogglecommentpledge(true)}
                  >
                    <span className="material-symbols-outlined align-text-top font-size-20">
                      comment
                    </span>{" "}
                    <span className="fw-medium">
                      {commentsData.length > 1
                        ? `${commentsData.length} Comments`
                        : `${commentsData.length} Comment`}
                    </span>
                  </div>

                  <ShareOffcanvasNew
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                  />
                </div>
              </div>

              <Collapse in={open}>
                <div id="commentcollapes" className="border-top mt-4 pt-4">
                  {commentsData.length > 0 && (
                    <ul className="list-inline m-o p-0 comment-list">
                      {commentsData.map((com, comKey) => (
                        <li
                          className="mb-3"
                          key={comKey}
                          style={{ display: "block" }}
                        >
                          <div className="comment-list-block">
                            <div className="d-flex align-items-center gap-3">
                              <div className="comment-list-user-img flex-shrink-0">
                                <img
                                  src={com?.user?.house?.picture ?? user1}
                                  alt="userimg"
                                  className="avatar-48 rounded-circle img-fluid"
                                  loading="lazy"
                                />
                              </div>
                              <div className="comment-list-user-data">
                                <div className="d-inline-flex align-items-center gap-1 flex-wrap">
                                  <h6 className="m-0">
                                    {com?.user?.name ?? ""}
                                  </h6>
                                  <span className="d-inline-block text-primary">
                                    <svg
                                      className="align-text-bottom"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="17"
                                      height="17"
                                      viewBox="0 0 17 17"
                                      fill="none"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M12.2483 0.216553H4.75081C2.13805 0.216553 0.5 2.0665 0.5 4.68444V11.7487C0.5 14.3666 2.13027 16.2166 4.75081 16.2166H12.2475C14.8689 16.2166 16.5 14.3666 16.5 11.7487V4.68444C16.5 2.0665 14.8689 0.216553 12.2483 0.216553Z"
                                        fill="currentColor"
                                      />
                                      <path
                                        d="M5.5 8.21627L7.50056 10.216L11.5 6.21655"
                                        stroke="white"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </span>
                                  <span className="fw-medium small text-capitalize">
                                    <ReactTimeAgo
                                      date={new Date()}
                                      locale="en-US"
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="comment-list-user-comment">
                              <div className="comment-list-comment">
                                {com.comment}
                              </div>
                              <div className="comment-list-action mt-2">
                                <ul className="list-inline m-0 p-0 d-flex align-items-center gap-2">
                                  <li>
                                    <div className="like-block position-relative d-flex align-items-center flex-shrink-0">
                                      <div className="like-data">
                                        <div className="dropdown">
                                          <span
                                            className="dropdown-toggle"
                                            data-bs-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            role="button"
                                          >
                                            <span className="material-symbols-outlined align-text-top font-size-18">
                                              thumb_up
                                            </span>
                                            <span className="fw-medium small">
                                              Likes
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <span
                                      className="fw-medium small"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#subcomment-collapse1"
                                      role="button"
                                      aria-expanded="false"
                                      aria-controls="collapseExample"
                                    >
                                      Reply
                                    </span>
                                  </li>
                                </ul>
                                <div
                                  className="add-comment-form-block collapse mt-3"
                                  id="subcomment-collapse1"
                                >
                                  <div className="d-flex align-items-center gap-3">
                                    <div className="flex-shrink-0">
                                      <img
                                        src={user1}
                                        alt="userimg"
                                        className="avatar-48 rounded-circle img-fluid"
                                        loading="lazy"
                                      />
                                    </div>
                                    <div className="add-comment-form">
                                      <form>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Write a Comment..."
                                        />
                                        <button
                                          type="submit"
                                          className="btn btn-primary font-size-12 text-capitalize px-5"
                                        >
                                          post
                                        </button>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}

                  <div className="add-comment-form-block">
                    <div className="d-flex align-items-center gap-3">
                      <div className="flex-shrink-0">
                        <img
                          src={user1}
                          alt="userimg"
                          className="avatar-48 rounded-circle img-fluid"
                          loading="lazy"
                        />
                      </div>
                      <div className="add-comment-form">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Write a Comment..."
                          onChange={handleCommentChange}
                          disabled={saveCommentLoading}
                          ref={commentInputRef}
                        />
                        <button
                          type="button"
                          className="btn btn-primary font-size-12 text-capitalize px-5"
                          disabled={commentValue === "" || saveCommentLoading}
                          onClick={handleSaveComment}
                        >
                          post
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Collapse>
              <Collapse in={open2}>
                <div id="commentcollapes" className="border-top mt-4 pt-4">
                  <ul className="list-inline m-o p-0 comment-list">
                    <li className="mb-3" style={{ display: "block" }}>
                      <div className="comment-list-block">
                        <div className="d-flex align-items-center gap-3">
                          <div className="comment-list-user-img flex-shrink-0">
                            <img
                              src={data.picture}
                              alt="userimg"
                              className="avatar-48 rounded-circle img-fluid"
                              loading="lazy"
                            />
                          </div>
                          <div className="comment-list-user-data">
                            <div className="d-inline-flex align-items-center gap-1 flex-wrap">
                              <h6 className="m-0">{data.name}</h6>
                              <span className="d-inline-block text-primary">
                                <svg
                                  className="align-text-bottom"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="17"
                                  height="17"
                                  viewBox="0 0 17 17"
                                  fill="none"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M12.2483 0.216553H4.75081C2.13805 0.216553 0.5 2.0665 0.5 4.68444V11.7487C0.5 14.3666 2.13027 16.2166 4.75081 16.2166H12.2475C14.8689 16.2166 16.5 14.3666 16.5 11.7487V4.68444C16.5 2.0665 14.8689 0.216553 12.2483 0.216553Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M5.5 8.21627L7.50056 10.216L11.5 6.21655"
                                    stroke="white"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </span>
                              <span className="fw-medium small text-capitalize">
                                <ReactTimeAgo
                                  date={new Date(data.createdAt)}
                                  locale="en-US"
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="comment-list-user-comment">
                          <div className="comment-list-comment">
                            <span>
                              Needs the following: <br />
                            </span>
                            {pledgeData.map((item, keyItem) => (
                              <span key={keyItem} style={{ display: "block" }}>
                                {`${item.data.name}${
                                  item.from === "item"
                                    ? ` (${item.data.quantity})`
                                    : ""
                                }`}
                                <br />
                              </span>
                            ))}
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div className="add-comment-form-block">
                    <div className="d-flex align-items-center gap-3">
                      <div className="flex-shrink-0">
                        <img
                          src={user1}
                          alt="userimg"
                          className="avatar-48 rounded-circle img-fluid"
                          loading="lazy"
                        />
                      </div>
                      <div className="add-comment-form">
                        <Form.Select
                          style={{
                            height: "59px",
                            padding: "0.575rem 7.5rem 0.575rem 0.75rem",
                            backgroundPosition: "right 9rem center",
                          }}
                          onChange={handleSelectPledge}
                        >
                          <option value="0">Please select</option>
                          {pledgeData.map((item, keyItem) => (
                            <option key={keyItem} value={item.data.id}>
                              {`${item.data.name}${
                                item.from === "item"
                                  ? ` (${item.data.quantity})`
                                  : ""
                              }`}
                            </option>
                          ))}
                        </Form.Select>
                        <button
                          type="button"
                          className="btn btn-primary font-size-12 text-capitalize px-5"
                          disabled={
                            selectedPledge.length === 0 || savePledgeLoading
                          }
                          onClick={handleSavePledge}
                        >
                          {savePledgeLoading ? "Saving..." : "Pledge"}
                        </button>
                      </div>
                    </div>
                  </div>
                  {selectedPledge.length > 0 && (
                    <div className="add-comment-form-block">
                      <div className="d-flex align-items-center gap-3">
                        <div className="flex-shrink-0">
                          <img
                            src={user1}
                            alt="userimg"
                            className="avatar-48 rounded-circle img-fluid"
                            loading="lazy"
                            style={{ opacity: 0 }}
                          />
                        </div>
                        <div className="add-comment-form">
                          {selectedPledge.map((item, keyItem) => (
                            <div
                              style={{
                                display: "flex",
                                gap: "5px",
                                backgroundColor: "var(--bs-gray-100)",
                                padding: "0.75rem 1rem",
                                borderRadius: "var(--bs-border-radius-lg)",
                                justifyContent: "space-between",
                              }}
                              className="mt-2"
                              key={keyItem}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  gap: "5px",
                                  alignItems: "center",
                                }}
                              >
                                <p style={{ marginBottom: 0 }}>{item.name}</p>
                                {item.from === "item" && (
                                  <>
                                    <input
                                      onChange={(e) =>
                                        handleSetQuantity(
                                          e.target.value,
                                          keyItem
                                        )
                                      }
                                    />
                                    <p
                                      style={{ marginBottom: 0 }}
                                    >{`/ ${item.origQuantity}`}</p>
                                  </>
                                )}
                              </div>
                              <button
                                type="button"
                                className="btn d-inline-flex btn-danger m-1 p-1"
                                style={{ position: "unset", transform: "none" }}
                                onClick={() => handleRemovePledge(keyItem)}
                              >
                                <i className="material-symbols-outlined me-0">
                                  close
                                </i>
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Collapse>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
};

export default EventFeedItem;
