import React, { useState } from "react";

import { Col, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";
import { NewsModel } from "../../model/news/newsmodel";

const user4 = require("../../assets/images/user/04.jpg");

type Props = {
  data: NewsModel;
};

const NewsFeedItem = ({ data }: Props) => {
  const [imageController, setImageController] = useState({
    toggler: false,
    slide: 1,
  });
  function imageOnSlide(number) {
    setImageController({
      toggler: !imageController.toggler,
      slide: number,
    });
  }

  return (
    <Col sm={12} className="special-post">
      <div className="card card-block card-stretch card-height">
        <div className="card-body">
          <div className="user-post-data">
            <div className="d-flex justify-content-between align-items-center">
              <div className="me-3 flex-shrik-0">
                <img
                  className="border border-2 rounded-circle user-post-profile"
                  src={user4}
                  alt=""
                />
              </div>
              <div className="w-100">
                <div className=" d-flex align-items-center justify-content-between">
                  <div>
                    <h6 className="mb-0 d-inline-block">Rebecca Mcguire</h6>{" "}
                    <span className="d-inline-block text-primary">
                      <svg
                        className="align-text-bottom"
                        width="17"
                        height="17"
                        viewBox="0 0 17 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.8457 0H4.34822C1.73547 0 0.0974121 1.84995 0.0974121 4.46789V11.5321C0.0974121 14.1501 1.72768 16 4.34822 16H11.8449C14.4663 16 16.0974 14.1501 16.0974 11.5321V4.46789C16.0974 1.84995 14.4663 0 11.8457 0Z"
                          fill="currentColor"
                        ></path>
                        <path
                          d="M5.09741 7.99978L7.09797 9.9995L11.0974 6.00006"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    </span>{" "}
                    <p className="mb-0 d-inline-block text-capitalize fw-medium">
                      {data.title}
                    </p>
                    <p className="mb-0">
                      <ReactTimeAgo
                        date={new Date(data.sentAt)}
                        locale="en-US"
                      />
                    </p>
                  </div>
                  <div className="card-post-toolbar">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="lh-1"
                        id="post-option"
                        as="span"
                        bsPrefix=" "
                      >
                        <span className="material-symbols-outlined">
                          more_horiz
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu m-0 p-0">
                        <Dropdown.Item className=" p-3">
                          <div className="d-flex align-items-top">
                            <span className="material-symbols-outlined">
                              save
                            </span>
                            <div className="data ms-2">
                              <h6>Save Post</h6>
                              <p className="mb-0">
                                Add this to your saved items
                              </p>
                            </div>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item className="p-3">
                          <div className="d-flex align-items-top">
                            <span className="material-symbols-outlined">
                              cancel
                            </span>
                            <div className="data ms-2">
                              <h6>Hide Post</h6>
                              <p className="mb-0">See fewer posts like this.</p>
                            </div>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item className=" p-3">
                          <div className="d-flex align-items-top">
                            <span className="material-symbols-outlined">
                              person_remove
                            </span>
                            <div className="data ms-2">
                              <h6>Unfollow User</h6>
                              <p className="mb-0">
                                Stop seeing posts but stay friends.
                              </p>
                            </div>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item className=" p-3">
                          <div className="d-flex align-items-top">
                            <span className="material-symbols-outlined">
                              notifications
                            </span>
                            <div className="data ms-2">
                              <h6>Notifications</h6>
                              <p className="mb-0">
                                Turn on notifications for this post
                              </p>
                            </div>
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <p className="mb-0">{data.description}</p>
            {/* <ul className="list-inline m-0 p-0 d-flex flex-wrap gap-1">
              <li>
                <Link to="#">#neighbours</Link>
              </li>
              <li>
                <Link to="#">#xmas</Link>
              </li>
              <li>
                <Link to="#">#boardevent</Link>
              </li>
              <li>
                <Link to="#">#together</Link>
              </li>
              <li>
                <Link to="#">#celebration</Link>
              </li>
            </ul> */}
          </div>
          <div className="user-post mt-4">
            {data.documents.map((doc, docKey) => (
              <Link
                onClick={() => imageOnSlide(docKey)}
                to="#"
                className="rounded"
              >
                <img
                  src={doc.file}
                  alt="post-images"
                  className="img-fluid rounded w-100"
                  loading="lazy"
                />
              </Link>
            ))}
          </div>
        </div>
      </div>
    </Col>
  );
};

export default NewsFeedItem;
