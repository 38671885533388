import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Button, Dropdown, ProgressBar } from "react-bootstrap";
import {
  useGetVotingListQuery,
  useSaveVoteMutation,
} from "../../store/query/voting/votingquery";
import {
  errorDisplayOrNavigate,
  showToastInfo,
  showToastSuccess,
} from "../../utilities/notificationToast";
const user12 = require("../../assets/images/user/15.jpg");
const user01 = require("../../assets/images/user/01.jpg");
const user2 = require("../../assets/images/user/02.jpg");
const user3 = require("../../assets/images/user/03.jpg");
const user4 = require("../../assets/images/user/04.jpg");
const user11 = require("../../assets/images/user/14.jpg");
const adsImg = require("../../assets/images/page-img/ads.jpg");

type Props = {};

const TempVotingComp = (props: Props) => {
  const { data, isLoading, error } = useGetVotingListQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const [voteId, setVoteId] = useState<number | null>(null);
  const [whoVoted, setWhoVoted] = useState<string | null>(null);
  const [
    saveVoteMutute,
    {
      isLoading: saveVoteLoading,
      error: saveVoteError,
      reset: saveVoteReset,
      data: saveVoteData,
      isSuccess: saveVoteSuccess,
    },
  ] = useSaveVoteMutation();

  if (error) {
    errorDisplayOrNavigate({ error, toastId: "error-list" });
  }

  if (saveVoteError) {
    errorDisplayOrNavigate({ error: saveVoteError, toastId: "error-vote" });

    saveVoteReset();
  }

  if (saveVoteSuccess) {
    showToastSuccess({ text: saveVoteData.message, toastId: "vote-success" });
    saveVoteReset();
  }

  useEffect(() => {
    if (saveVoteSuccess) {
      setVoteId(null);
    }
  }, [saveVoteSuccess]);

  useEffect(() => {
    if (data) {
      const voted = data.candidates.find((item) => item.voted === true);

      if (voted) {
        setWhoVoted(`${voted.ownerFirstName} ${voted.ownerLastName}`);
      }
    }
  }, [data]);

  return (
    <>
      <Col sm={12} className="special-post">
        <div className="card card-block card-stretch card-height">
          <div className="card-body">
            {isLoading ? (
              <p>Loading...</p>
            ) : (
              <>
                <div className="user-post-data">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="me-3 flex-shrik-0">
                      <img
                        className="border border-2 rounded-circle user-post-profile"
                        src={user11}
                        alt=""
                      />
                    </div>
                    <div className="w-100">
                      <div className=" d-flex align-items-center justify-content-between">
                        <div>
                          <h6 className="mb-0 d-inline-block">{data.name}</h6>{" "}
                          <span className="d-inline-block text-primary">
                            <svg
                              className="align-text-bottom"
                              width="17"
                              height="17"
                              viewBox="0 0 17 17"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M11.8457 0H4.34822C1.73547 0 0.0974121 1.84995 0.0974121 4.46789V11.5321C0.0974121 14.1501 1.72768 16 4.34822 16H11.8449C14.4663 16 16.0974 14.1501 16.0974 11.5321V4.46789C16.0974 1.84995 14.4663 0 11.8457 0Z"
                                fill="currentColor"
                              ></path>
                              <path
                                d="M5.09741 7.99978L7.09797 9.9995L11.0974 6.00006"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                            </svg>
                          </span>{" "}
                          <p className="mb-0">12 Hours ago</p>
                        </div>
                        <div className="card-post-toolbar">
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="lh-1"
                              id="post-option"
                              as="span"
                            >
                              <span className="material-symbols-outlined">
                                more_horiz
                              </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className=" m-0 p-0">
                              <Dropdown.Item className=" p-3">
                                <div className="d-flex align-items-top">
                                  <span className="material-symbols-outlined">
                                    save
                                  </span>
                                  <div className="data ms-2">
                                    <h6>Save Post</h6>
                                    <p className="mb-0">
                                      Add this to your saved items
                                    </p>
                                  </div>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item className="p-3">
                                <div className="d-flex align-items-top">
                                  <span className="material-symbols-outlined">
                                    cancel
                                  </span>
                                  <div className="data ms-2">
                                    <h6>Hide Post</h6>
                                    <p className="mb-0">
                                      See fewer posts like this.
                                    </p>
                                  </div>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item className=" p-3">
                                <div className="d-flex align-items-top">
                                  <span className="material-symbols-outlined">
                                    person_remove
                                  </span>
                                  <div className="data ms-2">
                                    <h6>Unfollow User</h6>
                                    <p className="mb-0">
                                      Stop seeing posts but stay friends.
                                    </p>
                                  </div>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item className=" p-3">
                                <div className="d-flex align-items-top">
                                  <span className="material-symbols-outlined">
                                    notifications
                                  </span>
                                  <div className="data ms-2">
                                    <h6>Notifications</h6>
                                    <p className="mb-0">
                                      Turn on notifications for this post
                                    </p>
                                  </div>
                                </div>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="user-post mt-4">
                  <div className="text-center">
                    <img src={data.picture} alt="ads" className="img-fluid" />
                    <Link
                      to="#"
                      className="btn btn-primary w-100 rounded-top-0"
                    >
                      <span className="d-flex align-items-center justify-content-between gap-1">
                        <span>Voted Now</span>
                        <span className="material-symbols-outlined">
                          chevron_right
                        </span>
                      </span>
                    </Link>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Col>
      <Col sm={12} className="special-post">
        <div className="card card-block card-stretch card-height">
          <div className="card-body">
            {isLoading ? (
              <p>Loading...</p>
            ) : (
              <>
                <div className="user-post-data">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="me-3 flex-shrik-0">
                      <img
                        className="border border-2 rounded-circle user-post-profile"
                        src={user12}
                        alt=""
                      />
                    </div>
                    <div className="w-100">
                      <div className=" d-flex align-items-center justify-content-between">
                        <div>
                          <h6 className="mb-0 d-inline-block">Tomas Hadden</h6>{" "}
                          <span className="d-inline-block text-primary">
                            <svg
                              className="align-text-bottom"
                              width="17"
                              height="17"
                              viewBox="0 0 17 17"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M11.8457 0H4.34822C1.73547 0 0.0974121 1.84995 0.0974121 4.46789V11.5321C0.0974121 14.1501 1.72768 16 4.34822 16H11.8449C14.4663 16 16.0974 14.1501 16.0974 11.5321V4.46789C16.0974 1.84995 14.4663 0 11.8457 0Z"
                                fill="currentColor"
                              ></path>
                              <path
                                d="M5.09741 7.99978L7.09797 9.9995L11.0974 6.00006"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                            </svg>
                          </span>{" "}
                          <span className="mb-0 d-inline-block text-capitalize fw-medium">
                            created a poll
                          </span>
                          <p className="mb-0 text-gray">15 Hours ago</p>
                        </div>
                        <div className="card-post-toolbar">
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="lh-1"
                              id="post-option"
                              as="span"
                            >
                              <span className="material-symbols-outlined">
                                more_horiz
                              </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu m-0 p-0">
                              <Dropdown.Item className=" p-3">
                                <div className="d-flex align-items-top">
                                  <span className="material-symbols-outlined">
                                    save
                                  </span>
                                  <div className="data ms-2">
                                    <h6>Save Post</h6>
                                    <p className="mb-0">
                                      Add this to your saved items
                                    </p>
                                  </div>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item className="p-3">
                                <div className="d-flex align-items-top">
                                  <span className="material-symbols-outlined">
                                    cancel
                                  </span>
                                  <div className="data ms-2">
                                    <h6>Hide Post</h6>
                                    <p className="mb-0">
                                      See fewer posts like this.
                                    </p>
                                  </div>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item className=" p-3">
                                <div className="d-flex align-items-top">
                                  <span className="material-symbols-outlined">
                                    person_remove
                                  </span>
                                  <div className="data ms-2">
                                    <h6>Unfollow User</h6>
                                    <p className="mb-0">
                                      Stop seeing posts but stay friends.
                                    </p>
                                  </div>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item className=" p-3">
                                <div className="d-flex align-items-top">
                                  <span className="material-symbols-outlined">
                                    notifications
                                  </span>
                                  <div className="data ms-2">
                                    <h6>Notifications</h6>
                                    <p className="mb-0">
                                      Turn on notifications for this post
                                    </p>
                                  </div>
                                </div>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="user-post mt-4">
                  <h5 className="mb-4 text-capitalize">{data.descripiton}</h5>
                  <ul className="list-inline m-0 p-0">
                    {data?.candidates &&
                      data.candidates.map((item, i) => (
                        <li className="mb-4" key={i}>
                          <div className="d-flex gap-3">
                            <div className="flex-shrink-0">
                              <h6 className="mb-0">{item.tally}</h6>
                            </div>
                            <div className="w-100">
                              <div className="d-flex align-items-center justify-content-between gap-3 w-100 mb-1">
                                <h6>{`${item.ownerFirstName} ${item.ownerLastName}`}</h6>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="poll"
                                    id="python"
                                    defaultChecked={item.voted}
                                    onClick={(e) => {
                                      if (whoVoted) {
                                        showToastInfo({
                                          text: `You already voted for ${whoVoted}`,
                                          toastId: "info-vote",
                                        });
                                        e.preventDefault();
                                      } else {
                                        setVoteId(item.id);
                                      }
                                      // saveVoteMutute({ candidateId: item.id });
                                    }}
                                  />
                                </div>
                              </div>
                              <ProgressBar
                                now={item.tally}
                                style={{ height: ".5rem" }}
                              />
                            </div>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
                <div className="post-meta-likes mt-4 pt-4 border-top">
                  <div className="d-flex align-items-center justify-content-between gap-3 flex-wrap">
                    <div className="d-flex align-items-center gap-2 flex-wrap">
                      <ul className="list-inline m-0 p-0 post-user-liked-list">
                        <li>
                          <img
                            src={user01}
                            alt="userimg"
                            className="rounded-circle img-fluid userimg"
                            loading="lazy"
                          />
                        </li>{" "}
                        <li>
                          <img
                            src={user2}
                            alt="userimg"
                            className="rounded-circle img-fluid userimg"
                            loading="lazy"
                          />
                        </li>{" "}
                        <li>
                          <img
                            src={user3}
                            alt="userimg"
                            className="rounded-circle img-fluid userimg"
                            loading="lazy"
                          />
                        </li>{" "}
                        <li>
                          <img
                            src={user4}
                            alt="userimg"
                            className="rounded-circle img-fluid userimg"
                            loading="lazy"
                          />
                        </li>{" "}
                      </ul>
                      <h6 className="m-0 font-size-14 lh-lg">
                        Total Votes: <b>25</b>
                      </h6>
                    </div>
                    <Button
                      type="button"
                      disabled={!voteId || saveVoteLoading}
                      className="btn btn-primary"
                      onClick={() => saveVoteMutute({ candidateId: voteId })}
                    >
                      {saveVoteLoading ? "Saving Vote" : "Vote"}
                    </Button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Col>
    </>
  );
};

export default TempVotingComp;
