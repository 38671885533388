import React, { useState, useEffect } from "react";
import {
  Nav,
  Form,
  Card,
  Container,
  Image,
  Dropdown,
  Navbar,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

//image
import Logo from "../../../../assets/images/BE-logo.png";
import user1 from "../../../../assets/images/user/1.jpg";
import user2 from "../../../../assets/images/user/02.jpg";
import user3 from "../../../../assets/images/user/03.jpg";
import user4 from "../../../../assets/images/user/04.jpg";
import user5 from "../../../../assets/images/user/01.jpg";
import user13 from "../../../../assets/images/user/07.jpg";

import shop1 from "../../../../assets/images/store/06.jpg";
import shop2 from "../../../../assets/images/store/02.jpg";
import shop3 from "../../../../assets/images/store/01.jpg";

// Import selectors & action from setting store
import * as SettingSelector from "../../../../store/setting/selectors";

// Redux Selector / Action
import { useSelector } from "react-redux";
import SearchModal from "../../../search-modal";
import { useAuthContext } from "../../../../contexts/auth/AuthContext";
import { useGetApiClient } from "../../../../api/useApiClient";
import { errorDisplayOrNavigate } from "../../../../utilities/notificationToast";

const Header = ({}) => {
  const navigate = useNavigate();
  const { authUser } = useAuthContext();

  const firstname = localStorage.getItem("firstName");
  const lastname = localStorage.getItem("lastName");

  const appName = useSelector(SettingSelector.app_name);

  const [active, setActive] = useState("home");

  const minisidebar = () => {
    const sidebarMini = document.getElementsByTagName("ASIDE")[0];
    if (sidebarMini.classList.contains("sidebar-mini")) {
      sidebarMini.classList.remove("sidebar-mini");
    } else {
      sidebarMini.classList.add("sidebar-mini");
    }
  };

  const handleSwitchRoles = async (id) => {
    // localStorage.clear();
    // console.log(id)
    try {
      const { data } = await useGetApiClient.get(`/general/role/switch/${id}`);
      if (data?.error) throw new Error(data?.error);
      if (id === 1) {
        window.open(
          `${data?.redirectTo}/dashboard/?token=${data?.token}`,
          "_blank"
        );
        // window.open(`http://localhost:8000/dashboard/?token=${data?.token}`, "_blank");
      } else {
        // window.location.reload();
        window.open(
          `${data?.redirectTo}/dashboard/?token=${data?.token}`,
          "_blank"
        );
      }
    } catch (error) {
      errorDisplayOrNavigate({
        text: error?.response?.data?.message,
        toastId: "switch-role-erroe",
      });
      // toast.error(error?.response?.data?.error);
    }
  };

  const dropdownContent = document.querySelectorAll(".sub-drop");
  if (dropdownContent) {
    dropdownContent.forEach((element) => {
      setTimeout(() => {
        element.style = "";
      }, 100);
    });
  }

  useEffect(() => {
    if (!localStorage.getItem("token", "firstName", "lastName")) {
      // navigate("/auth/sign-in");
      toast.success("Sign out success");
    }
  });

  // console.log(authUser)

  return (
    <>
      {/* <div className="position-relative"> */}
      <div className="iq-top-navbar border-bottom">
        <Navbar
          expand="lg"
          variant="light"
          className="nav navbar navbar-expand-lg navbar-light iq-navbar p-lg-0"
        >
          <Container fluid className="navbar-inner">
            <div className="d-flex align-items-center pb-2 pb-lg-0 d-xl-none">
              <Link
                to="/"
                className="d-flex align-items-center iq-header-logo navbar-brand d-block d-xl-none"
              >
                <img src={Logo} width="57" />
              </Link>
              <Link
                className="sidebar-toggle"
                data-toggle="sidebar"
                data-active="true"
                onClick={minisidebar}
                to="#"
              >
                <div className="icon material-symbols-outlined iq-burger-menu">
                  {" "}
                  menu{" "}
                </div>
              </Link>
            </div>

            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center justify-content-between product-offcanvas">
                <div
                  className="offcanvas offcanvas-end shadow-none iq-product-menu-responsive d-none d-xl-block"
                  tabIndex="-1"
                  id="offcanvasBottomNav"
                >
                  {/* <div className="offcanvas-body">
                    <ul className="iq-nav-menu list-unstyled">
                      <li className="nav-item">
                        <Link
                          className={`nav-link menu-arrow justify-content-start ${
                            active === "home" ? "active" : ""
                          }`}
                          to="/"
                          onClick={() => setActive("home")}
                        >
                          <span className="nav-text">Home</span>
                        </Link>
                      </li>
                     <li className="nav-item">
                        <Link
                          className={`nav-link menu-arrow justify-content-start ${
                            active === "Blog Detail" ? "active" : ""
                          }`}
                          to="/dashboard/blog/blog-detail"
                          onClick={() => setActive("Blog Detail")}
                        >
                          <span className="nav-text">Blog</span>
                        </Link>
                      </li>
                       <li className="nav-item">
                        <Link
                          className={`nav-link menu-arrow justify-content-start ${
                            active === "blog" ? "active" : ""
                          }`}
                          onClick={() => setActive("blog")}
                          data-bs-toggle="collapse"
                          to="#blogData"
                          role="button"
                          aria-expanded="false"
                          aria-controls="blogData"
                        >
                          <span className="nav-text">Blog</span>
                        </Link>
                        <ul
                          className="iq-header-sub-menu list-unstyled collapse shadow"
                          id="blogData"
                        >
                          <li className="nav-item">
                            <Link
                              className={`nav-link ${
                                active === "Blog Grid" ? "active" : ""
                              }`}
                              to="/dashboard/blog/blog-grid"
                              onClick={() => setActive("Blog Grid")}
                            >
                              Blog Grid
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className={`nav-link ${
                                active === "Blog List" ? "active" : ""
                              }`}
                              to="/dashboard/blog/blog-list"
                              onClick={() => setActive("Blog List")}
                            >
                              Blog List
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className={`nav-link ${
                                active === "Blog Detail" ? "active" : ""
                              }`}
                              to="/dashboard/blog/blog-detail"
                              onClick={() => setActive("Blog Detail")}
                            >
                              Blog Detail
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </div>
              <Dropdown
                bsPrefix=" "
                className="iq-search-bar device-search position-relative d-none d-lg-block"
              >
                <Dropdown.Toggle
                  as="form"
                  bsPrefix=" "
                  action="#"
                  className="searchbox open-modal-search"
                >
                  <Link className="search-link" to="#">
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="7.82491"
                        cy="7.82495"
                        r="6.74142"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></circle>
                      <path
                        d="M12.5137 12.8638L15.1567 15.5"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </Link>
                  <Form.Control
                    type="text"
                    className="text search-input form-control bg-light-subtle"
                    placeholder="Search for people or groups..."
                  />
                  <Link className="d-lg-none d-flex d-none d-lg-block" to="/">
                    <span className="material-symbols-outlined">search12</span>
                  </Link>
                </Dropdown.Toggle>
                <SearchModal />
              </Dropdown>
            </div>

            <ul className="navbar-nav navbar-list">
              <Dropdown as="li" className="nav-items">
                <Dropdown.Toggle
                  bsPrefix=" "
                  as="a"
                  to="#"
                  className=" d-flex align-items-center"
                  id="mail-drop"
                >
                  <span className="material-symbols-outlined position-relative">
                    event
                    <span className="bg-primary text-white shopping-badge">
                      3
                    </span>
                  </span>
                  <span className="mobile-text d-none ms-3">Events</span>
                </Dropdown.Toggle>
                <Dropdown.Menu
                  className={`sub-drop header-notification `}
                  data-bs-popper="static"
                >
                  <Card className="shadow m-0">
                    <Card.Header className="px-0 pb-4 mx-5 border-bottom">
                      <div className="header-title">
                        <h5 className="fw-semibold">Events</h5>
                      </div>
                    </Card.Header>
                    <Card.Body className="p-0 rounded-0">
                      <div className="item-header-scroll">
                        <Link to="#" className="text-body">
                          <div className="thread d-flex justify-content-between rounded-0">
                            <div>
                              <img
                                className="avatar-45 rounded align-top"
                                src={shop1}
                                alt=""
                                loading="lazy"
                              />{" "}
                              <div className="ms-3 d-inline-block">
                                <h6 className="font-size-14">Event Assigned</h6>
                                <span className="font-size-14 fw-semibold">
                                  50pcs BBQ
                                </span>
                              </div>
                            </div>
                            <span className="material-symbols-outlined">
                              close
                            </span>
                          </div>
                        </Link>
                        <Link to="#" className="text-body">
                          <div className="thread d-flex justify-content-between rounded-0">
                            <div>
                              <img
                                className="avatar-45 rounded align-top"
                                src={shop2}
                                alt=""
                                loading="lazy"
                              />{" "}
                              <div className="ms-3 d-inline-block">
                                <h6 className="font-size-14">Board Voting</h6>
                                <span className="font-size-14 fw-semibold">
                                  Voted
                                </span>
                              </div>
                            </div>
                            <span className="material-symbols-outlined">
                              close
                            </span>
                          </div>
                        </Link>
                        <Link to="#" className="text-body">
                          <div className="thread d-flex justify-content-between rounded-0">
                            <div>
                              <img
                                className="avatar-45 rounded align-top"
                                src={shop3}
                                alt=""
                                loading="lazy"
                              />{" "}
                              <div className="ms-3 d-inline-block">
                                <h6 className="font-size-14">
                                  Events Place - Reservation
                                </h6>
                                <span className="font-size-14 fw-semibold">
                                  May 30, 2024
                                </span>
                              </div>
                            </div>
                            <span className="material-symbols-outlined">
                              close
                            </span>
                          </div>
                        </Link>
                      </div>
                      <div className="m-5 mt-4">
                        <button
                          type="button"
                          className="btn btn-primary fw-500 w-100"
                        >
                          View All Events
                        </button>
                      </div>
                    </Card.Body>
                  </Card>
                </Dropdown.Menu>
              </Dropdown>

              <Nav.Item as="li" className="d-lg-none">
                <Dropdown bsPrefix=" " className="iq-search-bar device-search ">
                  <Dropdown.Toggle
                    as="form"
                    bsPrefix=" "
                    className="searchbo open-modal-search"
                  >
                    <Link className="d-lg-none d-flex text-body" to="#">
                      <svg
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="7.82491"
                          cy="7.82495"
                          r="6.74142"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></circle>
                        <path
                          d="M12.5137 12.8638L15.1567 15.5"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    </Link>
                    <Form.Control
                      type="text"
                      className="text search-input form-control bg-soft-primary  d-none d-lg-block"
                      placeholder="Search here..."
                    />
                  </Dropdown.Toggle>
                  <SearchModal />
                </Dropdown>
              </Nav.Item>

              <Dropdown className="nav-item " as="li">
                <Dropdown.Toggle
                  as="a"
                  bsPrefix=" "
                  to="#"
                  className="dropdown-toggle d-flex align-items-center"
                  id="group-drop"
                >
                  <span className="material-symbols-outlined">group</span>
                </Dropdown.Toggle>
                <Dropdown.Menu
                  className={`sub-drop sub-drop-large`}
                  aria-labelledby="group-drop"
                  data-bs-popper="static"
                >
                  <div className="card shadow m-0">
                    <div className="card-header d-flex justify-content-between px-0 pb-4 mx-5 border-bottom">
                      <div className="header-title">
                        <h5 className="fw-semibold">New HOA Members</h5>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="item-header-scroll">
                        <div className="iq-friend-request">
                          <div className="iq-sub-card-big d-flex align-items-center justify-content-between mb-4">
                            <div className="d-flex align-items-center">
                              <img
                                className="avatar-40 rounded-pill"
                                src={user5}
                                alt=""
                                loading="lazy"
                              />
                              <div className="ms-3">
                                <h6 className="mb-0 ">Jaques Amole</h6>
                                <p className="mb-0">4000 Gari Baldi Court</p>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <Link
                                to="#"
                                className="me-2 rounded bg-primary-subtle border-0 d-inline-block px-1"
                              >
                                <span className="material-symbols-outlined font-size-18 align-text-bottom">
                                  add
                                </span>
                              </Link>
                              <Link
                                to="#"
                                className="me-3 rounded bg-danger-subtle border-0 d-inline-block px-1"
                              >
                                <span className="material-symbols-outlined font-size-18 align-text-bottom">
                                  close
                                </span>
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="iq-friend-request">
                          <div className="iq-sub-card-big d-flex align-items-center justify-content-between mb-4">
                            <div className="d-flex align-items-center">
                              <img
                                className="avatar-40 rounded-pill"
                                src={user2}
                                alt=""
                                loading="lazy"
                              />
                              <div className="ms-3">
                                <h6 className="mb-0 ">Lucy Tania</h6>
                                <p className="mb-0">1000 Maleventum Way</p>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <Link
                                to="#"
                                className="me-2 rounded bg-primary-subtle border-0 d-inline-block px-1"
                              >
                                <span className="material-symbols-outlined font-size-18 align-text-bottom">
                                  add
                                </span>
                              </Link>
                              <Link
                                to="#"
                                className="me-3 rounded bg-danger-subtle border-0 d-inline-block px-1"
                              >
                                <span className="material-symbols-outlined font-size-18 align-text-bottom">
                                  close
                                </span>
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="iq-friend-request">
                          <div className="iq-sub-card-big d-flex align-items-center justify-content-between mb-4">
                            <div className="d-flex align-items-center">
                              <img
                                className="avatar-40 rounded-pill"
                                src={user3}
                                alt=""
                                loading="lazy"
                              />
                              <div className=" ms-3">
                                <h6 className="mb-0 ">Manny Petty</h6>
                                <p className="mb-0">50002 Moretto Courty</p>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <Link
                                to="#"
                                className="me-2 rounded bg-primary-subtle border-0 d-inline-block px-1"
                              >
                                <span className="material-symbols-outlined font-size-18 align-text-bottom">
                                  add
                                </span>
                              </Link>
                              <Link
                                to="#"
                                className="me-3 rounded bg-danger-subtle border-0 d-inline-block px-1"
                              >
                                <span className="material-symbols-outlined font-size-18 align-text-bottom">
                                  close
                                </span>
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="iq-friend-request">
                          <div className="iq-sub-card-big d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                              <img
                                className="avatar-40 rounded-pill"
                                src={user4}
                                alt=""
                                loading="lazy"
                              />
                              <div className="ms-3">
                                <h6 className="mb-0 ">Marsha Mello</h6>
                                <p className="mb-0">8009 San Cabrillo Court</p>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <Link
                                to="#"
                                className="me-2 rounded bg-primary-subtle border-0 d-inline-block px-1"
                              >
                                <span className="material-symbols-outlined font-size-18 align-text-bottom">
                                  add
                                </span>
                              </Link>
                              <Link
                                to="#"
                                className="me-3 rounded bg-danger-subtle border-0 d-inline-block px-1"
                              >
                                <span className="material-symbols-outlined font-size-18 align-text-bottom">
                                  close
                                </span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-center">
                        <button
                          type="button"
                          className="btn btn-primary fw-500 mt-4"
                        >
                          View More
                        </button>
                      </div>
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown as="li" className="nav-item">
                <Dropdown.Toggle
                  as="a"
                  className="search-toggle d-flex align-items-center"
                  id="notification-drop"
                >
                  <span className="material-symbols-outlined position-relative">
                    notifications
                    <span className="bg-primary text-white notification-badge"></span>
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu
                  className={`sub-drop header-notification `}
                  aria-labelledby="notification-drop"
                  data-bs-popper="static"
                >
                  <Card className=" m-0 shadow">
                    <div className="card-header d-flex justify-content-between px-0 pb-4 mx-5 border-bottom">
                      <div className="header-title">
                        <h5 className="fw-semibold">Notifications</h5>
                      </div>
                      <h6 className="material-symbols-outlined">settings</h6>
                    </div>
                    <Card.Body>
                      <div className="item-header-scroll">
                        <Link to="#">
                          <div className="d-flex gap-3 mb-4">
                            <img
                              className="avatar-32 rounded-pill"
                              src={user5}
                              alt=""
                            />
                            <div>
                              <h6 className="font-size-14">
                                Pete Sariya{" "}
                                <span className="text-body fw-normal">
                                  voted for
                                </span>{" "}
                                combination of colors from your brand palette{" "}
                              </h6>
                              <small className="text-body fw-500">
                                1 month ago
                              </small>
                            </div>
                          </div>
                        </Link>
                        <Link to="/dashboard/app/notification">
                          <div className="d-flex gap-3 mb-4">
                            <img
                              className="avatar-32 rounded-pill"
                              src={user2}
                              alt=""
                              loading="lazy"
                            />
                            <div>
                              <h6 className="font-size-14">
                                Dima Davydov{" "}
                                <span className="text-body fw-normal">
                                  replied to your
                                </span>{" "}
                                <span className="text-primary fw-semibold">
                                  Comment
                                </span>
                              </h6>
                              <small className="text-body fw-500">
                                1 month ago
                              </small>
                            </div>
                          </div>
                        </Link>
                        <Link to="/dashboard/app/notification">
                          <div className="d-flex gap-3 mb-4">
                            <img
                              className="avatar-32 rounded-pill"
                              src={user3}
                              alt=""
                              loading="lazy"
                            />
                            <div>
                              <h6 className="font-size-14">
                                Esther Howard{" "}
                                <span className="text-body fw-normal">
                                  reacted comment in to your{" "}
                                </span>
                                <span className="text-primary fw-semibold">
                                  post
                                </span>
                                .
                              </h6>
                              <small className="text-body fw-500">
                                19 min ago
                              </small>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <Link to="/dashboard/app/notification">
                        <button
                          type="button"
                          className="btn btn-primary fw-500 w-100"
                        >
                          View All Notifications
                        </button>
                      </Link>
                    </Card.Body>
                  </Card>
                </Dropdown.Menu>
              </Dropdown>

              <Nav.Item className="nav-item d-none d-lg-none">
                <Link
                  to="#"
                  className="dropdown-toggle d-flex align-items-center"
                  id="mail-drop-1"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="material-symbols-outlined">mail</i>
                  <span className="mobile-text  ms-3">Message</span>
                </Link>
              </Nav.Item>
              <Dropdown as="li" className="nav-item user-dropdown">
                <Dropdown.Toggle
                  as="a"
                  to="#"
                  className="d-flex align-items-center"
                  id="drop-down-arrow"
                >
                  <Image
                    src={authUser.picture}
                    className="img-fluid rounded-circle avatar-48 border border-2 me-3"
                    alt="user"
                    loading="lazy"
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu className={`sub-drop caption-menu `}>
                  <Card className="shadow-none m-0">
                    <Card.Header>
                      <div className="header-title">
                        <h5 className="mb-0 ">
                          <span>Hello </span>
                          <span>{firstname} </span>
                          <span>{lastname} </span>
                        </h5>
                      </div>
                    </Card.Header>
                    <Card.Body className="p-0 ">
                      <div className="d-flex align-items-center iq-sub-card border-0">
                        <span className="material-symbols-outlined">
                          line_style
                        </span>
                        <div className="ms-3">
                          <Link to="/dashboard/app/profile" className="mb-0 h6">
                            My Profile
                          </Link>
                        </div>
                      </div>
                      <div className="d-flex align-items-center iq-sub-card border-0">
                        <span className="material-symbols-outlined">
                          edit_note
                        </span>
                        <div className="ms-3">
                          <Link
                            to="/dashboard/app/user-profile-edit"
                            className="mb-0 h6"
                          >
                            Edit Profile
                          </Link>
                        </div>
                      </div>
                      <div className="d-flex align-items-center iq-sub-card border-0">
                        <span className="material-symbols-outlined">
                          manage_accounts
                        </span>
                        <div className="ms-3">
                          <Link
                            to="/dashboard/app/user-account-setting"
                            className="mb-0 h6"
                          >
                            Account settings
                          </Link>
                        </div>
                      </div>
                      <div className="d-flex align-items-center iq-sub-card border-0">
                        <span className="material-symbols-outlined">lock</span>
                        <div className="ms-3">
                          <Link
                            to="/dashboard/app/user-privacy-setting"
                            className="mb-0 h6"
                          >
                            Privacy Settings
                          </Link>
                        </div>
                      </div>
                      <div className="d-flex align-items-center iq-sub-card border-0">
                        <span className="material-symbols-outlined">
                          supervised_user_circle
                        </span>
                        <div className="ms-3">
                          <Dropdown>
                            <Dropdown.Toggle id="post-option" as="span">
                              <span className="mb-0 h6">Switch Role</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className=" m-3 p-0">
                              {authUser?.otherRoles?.map((role, idx) => (
                                <Dropdown.Item
                                  key={idx}
                                  className="p-3 iq-sub-card"
                                  onClick={() => handleSwitchRoles(role?.id)}
                                >
                                  <div className="d-flex align-items-top">
                                    <h6>{role?.name}</h6>
                                  </div>
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                      <div className="d-flex align-items-center iq-sub-card">
                        <span className="material-symbols-outlined">login</span>
                        <div className="ms-3">
                          <Link
                            to="/auth/sign-in"
                            className="mb-0 h6"
                            onClick={() => {
                              localStorage.clear();
                            }}
                          >
                            Sign out
                          </Link>
                        </div>
                      </div>
                      <div className=" iq-sub-card">
                        <h5>Chat Settings</h5>
                      </div>
                      <div className="d-flex align-items-center iq-sub-card border-0">
                        <i className="material-symbols-outlined text-success md-14">
                          circle
                        </i>
                        <div className="ms-3">Online</div>
                      </div>
                      <div className="d-flex align-items-center iq-sub-card border-0">
                        <i className="material-symbols-outlined text-warning md-14">
                          circle
                        </i>
                        <div className="ms-3">Away</div>
                      </div>
                      <div className="d-flex align-items-center iq-sub-card border-0">
                        <i className="material-symbols-outlined text-danger md-14">
                          circle
                        </i>
                        <div className="ms-3">Disconnected</div>
                      </div>
                      <div className="d-flex align-items-center iq-sub-card border-0">
                        <i className="material-symbols-outlined text-gray md-14">
                          circle
                        </i>
                        <div className="ms-3">Invisible</div>
                      </div>
                    </Card.Body>
                  </Card>
                </Dropdown.Menu>
              </Dropdown>
            </ul>
          </Container>
          {/* </Navbar> */}
        </Navbar>
      </div>
      {/* </div> */}

      {/* <div
        className={`modal-backdrop fade ${show ? "show" : "d-none"}`}
        onClick={handleClose}
      ></div> */}
    </>
  );
};

export default Header;
