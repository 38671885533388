import React, { useEffect, useState, useRef } from "react";
import {
  Col,
  Dropdown,
  OverlayTrigger,
  Tooltip,
  Collapse,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import ShareOffcanvasNew from "../../components/ShareOffcanvasNew";
import { BlogCommentModel, BlogModel } from "../../model/blog/blogmodel";
import ReactTimeAgo from "react-time-ago";
import {
  useSaveCommentMutation,
  useSaveLikeMutation,
} from "../../store/query/blog/blogquery";
import { ChangeEvent } from "preact/compat";
import { errorDisplayOrNavigate } from "../../utilities/notificationToast";

const user4 = require("../../assets/images/user/04.jpg");
const user1 = require("../../assets/images/user/1.jpg");
const user01 = require("../../assets/images/user/01.jpg");
const user2 = require("../../assets/images/user/02.jpg");
const user3 = require("../../assets/images/user/03.jpg");
const user5 = require("../../assets/images/page-img/fun.jpg");
const icon1 = require("../../assets/images/icon/01.png");
const icon2 = require("../../assets/images/icon/02.png");
const icon3 = require("../../assets/images/icon/03.png");
const icon4 = require("../../assets/images/icon/04.png");
const icon5 = require("../../assets/images/icon/05.png");
const icon6 = require("../../assets/images/icon/06.png");
const icon7 = require("../../assets/images/icon/07.png");

type Props = {
  data: BlogModel;
};

const BlogFeedItem = ({ data }: Props) => {
  const commentInputRef = useRef<HTMLInputElement>();
  const likedRef = useRef<boolean>(data.liked);
  const [
    saveCommmentMutate,
    {
      isLoading: saveCommentLoading,
      reset: saveCommentReset,
      error: saveCommentError,
    },
  ] = useSaveCommentMutation();
  const [
    saveLikeMutate,
    { isLoading: saveLikeLoading, reset: saveLikeReset, error: saveLikeError },
  ] = useSaveLikeMutation();
  const [modalShow2, setModalShow2] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [commentsData, setCommentsData] = useState<Partial<BlogCommentModel>[]>(
    data ? data.comments : []
  );
  const [likesCount, setLikeCounts] = useState<number>(data.likeCount);
  const [commentValue, setCommentValue] = useState<string>("");

  const [imageController, setImageController] = useState({
    toggler: false,
    slide: 1,
  });
  function imageOnSlide(number) {
    setImageController({
      toggler: !imageController.toggler,
      slide: number,
    });
  }

  const handleCommentChange = (e) => {
    setCommentValue(e.currentTarget.value);
  };

  const handleSaveComment = async () => {
    try {
      await saveCommmentMutate({ blogId: data.id, comment: commentValue });
      commentInputRef.current.value = "";
      setCommentsData((prev) => [{ comment: commentValue }, ...prev]);
    } catch (e) {
      console.log(e);
    }
  };

  const handleSaveLike = async (like: boolean) => {
    try {
      await saveLikeMutate({ blogId: data.id, liked: like });
      likedRef.current = like;
      setLikeCounts((prev) => (like ? prev + 1 : prev - 1));
    } catch (e) {
      console.log(e);
    }
  };

  if (saveCommentError) {
    errorDisplayOrNavigate({
      error: saveCommentError,
      toastId: "commnent-error",
    });

    saveCommentReset();
  }

  if (saveLikeError) {
    errorDisplayOrNavigate({
      error: saveCommentError,
      toastId: "like-error",
    });

    saveLikeReset();
  }

  return (
    <Col sm={12} className="special-post">
      <div className="card card-block card-stretch card-height">
        <div className="card-body">
          <div className="user-post-data">
            <div className="d-flex justify-content-between align-items-center">
              <div className="me-3 flex-shrik-0">
                <img
                  className="border border-2 rounded-circle user-post-profile"
                  src={user4}
                  alt=""
                />
              </div>
              <div className="w-100">
                <div className=" d-flex align-items-center justify-content-between">
                  <div>
                    <h6 className="mb-0 d-inline-block">Rebecca Mcguire</h6>{" "}
                    <span className="d-inline-block text-primary">
                      <svg
                        className="align-text-bottom"
                        width="17"
                        height="17"
                        viewBox="0 0 17 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.8457 0H4.34822C1.73547 0 0.0974121 1.84995 0.0974121 4.46789V11.5321C0.0974121 14.1501 1.72768 16 4.34822 16H11.8449C14.4663 16 16.0974 14.1501 16.0974 11.5321V4.46789C16.0974 1.84995 14.4663 0 11.8457 0Z"
                          fill="currentColor"
                        ></path>
                        <path
                          d="M5.09741 7.99978L7.09797 9.9995L11.0974 6.00006"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    </span>{" "}
                    <p className="mb-0 d-inline-block text-capitalize fw-medium">
                      {data.name}
                    </p>
                    <p className="mb-0">
                      <ReactTimeAgo
                        date={new Date(data.sentAt)}
                        locale="en-US"
                      />
                    </p>
                  </div>
                  <div className="card-post-toolbar">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="lh-1"
                        id="post-option"
                        as="span"
                        bsPrefix=" "
                      >
                        <span className="material-symbols-outlined">
                          more_horiz
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu m-0 p-0">
                        <Dropdown.Item className=" p-3">
                          <div className="d-flex align-items-top">
                            <span className="material-symbols-outlined">
                              save
                            </span>
                            <div className="data ms-2">
                              <h6>Save Post</h6>
                              <p className="mb-0">
                                Add this to your saved items
                              </p>
                            </div>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item className="p-3">
                          <div className="d-flex align-items-top">
                            <span className="material-symbols-outlined">
                              cancel
                            </span>
                            <div className="data ms-2">
                              <h6>Hide Post</h6>
                              <p className="mb-0">See fewer posts like this.</p>
                            </div>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item className=" p-3">
                          <div className="d-flex align-items-top">
                            <span className="material-symbols-outlined">
                              person_remove
                            </span>
                            <div className="data ms-2">
                              <h6>Unfollow User</h6>
                              <p className="mb-0">
                                Stop seeing posts but stay friends.
                              </p>
                            </div>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item className=" p-3">
                          <div className="d-flex align-items-top">
                            <span className="material-symbols-outlined">
                              notifications
                            </span>
                            <div className="data ms-2">
                              <h6>Notifications</h6>
                              <p className="mb-0">
                                Turn on notifications for this post
                              </p>
                            </div>
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <p className="mb-0">{data.description}</p>
            {/* <ul className="list-inline m-0 p-0 d-flex flex-wrap gap-1">
              <li>
                <Link to="#">#neighbours</Link>
              </li>
              <li>
                <Link to="#">#xmas</Link>
              </li>
              <li>
                <Link to="#">#boardevent</Link>
              </li>
              <li>
                <Link to="#">#together</Link>
              </li>
              <li>
                <Link to="#">#celebration</Link>
              </li>
            </ul> */}
          </div>
          <div className="user-post mt-4">
            {data.documents.map((doc, docKey) => (
              <Link
                onClick={() => imageOnSlide(docKey)}
                to="#"
                className="rounded"
              >
                <img
                  src={doc.file}
                  alt="post-images"
                  className="img-fluid rounded w-100"
                  loading="lazy"
                />
              </Link>
            ))}
          </div>
          <div className="post-meta-likes mt-4">
            <div className="d-flex align-items-center gap-2 flex-wrap">
              <ul className="list-inline m-0 p-0 post-user-liked-list">
                <li>
                  <img
                    src={user01}
                    alt="userimg"
                    className="rounded-circle img-fluid userimg"
                    loading="lazy"
                  />
                </li>{" "}
                <li>
                  <img
                    src={user2}
                    alt="userimg"
                    className="rounded-circle img-fluid userimg"
                    loading="lazy"
                  />
                </li>{" "}
                <li>
                  <img
                    src={user3}
                    alt="userimg"
                    className="rounded-circle img-fluid userimg"
                    loading="lazy"
                  />
                </li>{" "}
                <li>
                  <img
                    src={user4}
                    alt="userimg"
                    className="rounded-circle img-fluid userimg"
                    loading="lazy"
                  />
                </li>{" "}
              </ul>
              <div className="d-inline-flex align-items-center gap-1">
                <h6 className="m-0 font-size-14">Aliana Molex</h6>
                <span
                  className="text-capitalize font-size-14 fw-medium"
                  data-bs-toggle="modal"
                  data-bs-target="#likemodal"
                >
                  and 208 others liked this
                </span>
              </div>
            </div>
          </div>
          <div className="comment-area mt-4 pt-4 border-top">
            <div className="d-flex justify-content-between align-items-center flex-wrap">
              <div className="like-block position-relative d-flex align-items-center flex-shrink-0">
                <div className="like-data">
                  <div className="dropdown">
                    <span
                      className="dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      role="button"
                      style={{
                        color: likedRef.current
                          ? "#50b5ff"
                          : "rgb(126, 139, 154)",
                      }}
                      onClick={
                        saveLikeLoading
                          ? () => {}
                          : () => handleSaveLike(!likedRef.current)
                      }
                    >
                      <span
                        className={`material-symbols-outlined align-text-top font-size-20`}
                      >
                        thumb_up
                      </span>{" "}
                      <span className="fw-medium">
                        {likesCount > 1
                          ? `${likesCount} Likes`
                          : `${likesCount} Like`}
                      </span>
                    </span>
                    {/* <div className="dropdown-menu py-2 shadow">
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Like</Tooltip>}
                      >
                        <img src={icon1} className="img-fluid me-2" alt="" />
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Love</Tooltip>}
                      >
                        <img src={icon2} className="img-fluid me-2" alt="" />
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Happy</Tooltip>}
                      >
                        <img src={icon3} className="img-fluid me-2" alt="" />
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>HaHa</Tooltip>}
                      >
                        <img src={icon4} className="img-fluid me-2" alt="" />
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Think</Tooltip>}
                      >
                        <img src={icon5} className="img-fluid me-2" alt="" />
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Sad</Tooltip>}
                      >
                        <img src={icon6} className="img-fluid me-2" alt="" />
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Lovely</Tooltip>}
                      >
                        <img src={icon7} className="img-fluid me-2" alt="" />
                      </OverlayTrigger>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center gap-3 flex-shrink-0">
                <div
                  className="total-comment-block"
                  aria-controls="commentcollapes"
                  aria-expanded={open2}
                  onClick={() => setOpen2(!open2)}
                >
                  <span className="material-symbols-outlined align-text-top font-size-20">
                    comment
                  </span>{" "}
                  <span className="fw-medium">
                    {commentsData.length > 1
                      ? `${commentsData.length} Comments`
                      : `${commentsData.length} Comment`}
                  </span>
                </div>

                <div className="share-block d-flex align-items-center feather-icon">
                  <Link
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#share-btn"
                    onClick={() => setModalShow2(true)}
                    aria-controls="share-btn"
                    className="d-flex align-items-center"
                  >
                    <span className="material-symbols-outlined align-text-top font-size-20">
                      share
                    </span>
                    <span className="ms-1 fw-medium">Share</span>
                  </Link>
                </div>
                <ShareOffcanvasNew
                  show={modalShow2}
                  onHide={() => setModalShow2(false)}
                />
              </div>
            </div>
            <Collapse in={open2}>
              <div id="commentcollapes" className="border-top mt-4 pt-4">
                {commentsData.length > 0 && (
                  <ul className="list-inline m-o p-0 comment-list">
                    {commentsData.map((com, comKey) => (
                      <li className="mb-3" key={comKey}>
                        <div className="comment-list-block">
                          <div className="d-flex align-items-center gap-3">
                            <div className="comment-list-user-img flex-shrink-0">
                              <img
                                src={com?.user?.house?.picture ?? user1}
                                alt="userimg"
                                className="avatar-48 rounded-circle img-fluid"
                                loading="lazy"
                              />
                            </div>
                            <div className="comment-list-user-data">
                              <div className="d-inline-flex align-items-center gap-1 flex-wrap">
                                <h6 className="m-0">{com?.user?.name ?? ""}</h6>
                                <span className="d-inline-block text-primary">
                                  <svg
                                    className="align-text-bottom"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="17"
                                    height="17"
                                    viewBox="0 0 17 17"
                                    fill="none"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M12.2483 0.216553H4.75081C2.13805 0.216553 0.5 2.0665 0.5 4.68444V11.7487C0.5 14.3666 2.13027 16.2166 4.75081 16.2166H12.2475C14.8689 16.2166 16.5 14.3666 16.5 11.7487V4.68444C16.5 2.0665 14.8689 0.216553 12.2483 0.216553Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M5.5 8.21627L7.50056 10.216L11.5 6.21655"
                                      stroke="white"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </span>
                                <span className="fw-medium small text-capitalize">
                                  <ReactTimeAgo
                                    date={com?.createdAt ?? new Date()}
                                    locale="en-US"
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="comment-list-user-comment">
                            <div className="comment-list-comment">
                              {com.comment}
                            </div>
                            <div className="comment-list-action mt-2">
                              <ul className="list-inline m-0 p-0 d-flex align-items-center gap-2">
                                <li>
                                  <div className="like-block position-relative d-flex align-items-center flex-shrink-0">
                                    <div className="like-data">
                                      <div className="dropdown">
                                        <span
                                          className="dropdown-toggle"
                                          data-bs-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                          role="button"
                                        >
                                          <span className="material-symbols-outlined align-text-top font-size-18">
                                            thumb_up
                                          </span>
                                          <span className="fw-medium small">
                                            Likes
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <span
                                    className="fw-medium small"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#subcomment-collapse1"
                                    role="button"
                                    aria-expanded="false"
                                    aria-controls="collapseExample"
                                  >
                                    Reply
                                  </span>
                                </li>
                              </ul>
                              <div
                                className="add-comment-form-block collapse mt-3"
                                id="subcomment-collapse1"
                              >
                                <div className="d-flex align-items-center gap-3">
                                  <div className="flex-shrink-0">
                                    <img
                                      src={user1}
                                      alt="userimg"
                                      className="avatar-48 rounded-circle img-fluid"
                                      loading="lazy"
                                    />
                                  </div>
                                  <div className="add-comment-form">
                                    <form>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Write a Comment..."
                                      />
                                      <button
                                        type="submit"
                                        className="btn btn-primary font-size-12 text-capitalize px-5"
                                      >
                                        post
                                      </button>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}

                <div className="add-comment-form-block">
                  <div className="d-flex align-items-center gap-3">
                    <div className="flex-shrink-0">
                      <img
                        src={user1}
                        alt="userimg"
                        className="avatar-48 rounded-circle img-fluid"
                        loading="lazy"
                      />
                    </div>
                    <div className="add-comment-form">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Write a Comment..."
                        onChange={handleCommentChange}
                        disabled={saveCommentLoading}
                        ref={commentInputRef}
                      />
                      <button
                        type="button"
                        className="btn btn-primary font-size-12 text-capitalize px-5"
                        disabled={commentValue === "" || saveCommentLoading}
                        onClick={handleSaveComment}
                      >
                        post
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Collapse>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default BlogFeedItem;
